import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface posthashtag {
  hashtagId: number,
  hashtagName: string
}

interface comment {
  commentId : number,
  name : string,
  content : string,
  created : string,
  updated : string,
  uid : number,
  pid : number
}

interface post {
  pid : number,
  cid : {
    cid : number,
    cname : string,
    default_thumb : string
  },
  uid : {
    nickname : string,
    uid : number
  },
  hashtags : posthashtag[],
  comments : comment[],
  pname : string,
  pthumb : string,
  pthumbname : string,
  slug : string,
  content : string,
  hit : string,
  visible : boolean,
  created : string,
  updated : string
}

export default function Postcard(props : {post : post, className : string}) {
    const hyper = '/posts/' + props.post.pid;
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
      var tmp_tags = [''];
      props.post.hashtags.forEach((tag : posthashtag)=> {
          tmp_tags = [...tmp_tags, tag.hashtagName]
      });
      setTags(tmp_tags.slice(1));
      if (props.post.pthumb === '') {
        props.post.pthumb = props.post.cid.default_thumb;
      }
    }, [props.post])
    return (
    <div className={`${props.className} hover:scale-105 transition-transform duration-300 ease-in-out`}>
      <Link to={hyper}>
        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out">
          <img className="lg:h-48 md:h-36 w-full object-cover object-center" src={props.post.pthumb} alt={props.post.pthumbname}/>
          <div className="p-6">
            <div className="flex mb-1">
            <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">{props.post.cid.cname}</h2>
            <div className="ml-auto">
              {tags.map((tag) => 
                  <div>
                      <span id="badge-dismiss-indigo" className="inline-flex items-center px-2 py-1 me-2 text-xs font-medium text-indigo-800 bg-indigo-100 rounded dark:bg-indigo-900 dark:text-indigo-300">
                      #{tag}
                      </span>
                  </div>
                  )}
            </div>
            </div>
            <h1 className="title-font text-lg font-semibold text-gray-900 mb-3">{!props.post.visible && '🔒'}{props.post.pname}</h1>
            <p className="leading-relaxed mb-3">{props.post.slug}</p>
            <div className="flex items-center flex-wrap ">
              <div className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </div>
              <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                <svg className="w-4 h-4 mr-1" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>{props.post.hit}
              </span>
              <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                <svg className="w-4 h-4 mr-1" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                  <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                </svg>{props.post.comments.length}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
    );
}