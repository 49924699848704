import Footer from "../components/footer.tsx";
import SkillLevel from "../components/SkillLevel.tsx";
import LanguageSkillBox from "../components/LanguageSkillBox.tsx"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";

interface skill {
    skill : string,
    name : string,
    img : string,
    level : number,
    width : number,
    height : number
}

export default function About() {
  const [techSkill, setTechSkill] = useState('');
  const [listSkill, setListSkill] = useState<skill[]> ([]);
  const [visible, setVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const ref = useRef(null);
  const ref2 = useRef(null);
  var techskills = useMemo(() => ({
    cloud : [
      {
        skill : 'Cloud',
        name : 'AWS',
        img : 'https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/cloud/amazon.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Cloud',
        name : 'Azure',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/microsoft-azure.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Cloud',
        name : 'GCP',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/google-cloud.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Cloud',
        name : 'Docker',
        img : 'https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/cloud/docker.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Cloud',
        name : 'Terraform',
        img : 'https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/cloud/terraform.png',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Cloud',
        name : 'Kubernetes',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/kubernetes.svg',
        level : 1,
        width : 80,
        height : 80
      },
    ],
    database : [
      {
        skill : 'Database',
        name : 'MySQL',
        img : 'https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/databases/mysql.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Database',
        name : 'PostgreSQL',
        img : 'https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/databases/postgresql.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Database',
        name : 'MariaDB',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/mariadb-icon.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Database',
        name : 'Redis',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/redis.svg',
        level : 1,
        width : 80,
        height : 80
      },
    ],
    ci : [
      {
        skill : 'CI/CD',
        name : 'Travis CI',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/travis-ci.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'CI/CD',
        name : 'JenKins',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/jenkins.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'CI/CD',
        name : 'Github Actions',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/github-actions.svg',
        level : 1,
        width : 80,
        height : 80
      },
    ],
    collaborative : [
      {
        skill : 'Collaborative',
        name : 'Github',
        img : 'https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/cloud/github.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Collaborative',
        name : 'Jira',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/jira.svg',
        level : 1,
        width : 80,
        height : 80
      },
    ],
    ai : [
      {
        skill : 'AI/ML/DL',
        name : 'TensorFlow',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/tensorflow.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'AI/ML/DL',
        name : 'Pandas',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/pandas-icon.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'AI/ML/DL',
        name : 'Matplotlib',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/matplotlib-icon.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'AI/ML/DL',
        name : 'Pytorch',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/pytorch-icon.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'AI/ML/DL',
        name : 'Numpy',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/numpy.svg',
        level : 1,
        width : 80,
        height : 80
      },
    ],
    backend : [
      {
        skill : 'Backend',
        name : 'Django',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/django-icon.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Backend',
        name : 'FastAPI',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/fastapi-icon.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Backend',
        name : 'Flask',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/flask.svg',
        level : 1,
        width : 80,
        height : 80
      },
      {
        skill : 'Backend',
        name : 'Nginx',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/nginx.svg',
        level : 1,
        width : 80,
        height : 80
      },
    ],
    frontend : [
      {
        skill : 'Frontend',
        name : 'React',
        img : 'https://raw.githubusercontent.com/gilbarbara/logos/main/logos/react.svg',
        level : 1,
        width : 80,
        height : 80
      },
    ],
  }), [])

  const changeSkill = useCallback(async (skill) => {
    setVisible(false);
    setTechSkill(skill);
    setTimeout(() => {
      setListSkill(techskills[skill]);
    }, 400)
  }, [techskills])

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        },
        { threshold: 0.1 }
    );

    const currentRef = ref.current;

    if (currentRef) {
        observer.observe(currentRef);
    }

    return () => {
        if (currentRef) {
            observer.unobserve(currentRef);
        }
    };
}, []);

useEffect(() => {
  const observer = new IntersectionObserver(
      ([entry]) => {
          if (entry.isIntersecting) {
              setIsVisible2(true);
          } else {
              setIsVisible2(false);
          }
      },
      { threshold: 0.1 }
  );

  const currentRef = ref2.current;
  if (currentRef) {
      observer.observe(currentRef);
  }

  return () => {
      if (currentRef) {
          observer.unobserve(currentRef);
      }
  };
}, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);        
    }, 400);
    return () => clearTimeout(timer);

}, [listSkill]);

  
  useEffect(() => {
    changeSkill('cloud');
  }, [changeSkill])
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
          duration : 0.5,
          ease : 'easeInOut'
      }}
      >
      <div className="bg-white">
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto flex flex-wrap">
            <div className="flex flex-wrap w-full">
              <div className="lg:w-1/2 mb-10 lg:mb-0 w-full flex flex-col items-center rounded-lg gap-y-3" >
                <div className="relative me-4">
                    <img alt="Dev.K Thumbnail" src="https://i.namu.wiki/i/P1IEO4__UwFQ5_HKM1cf6h9xaBSRA8V9mB5CV1hU9pI_F9OVUU-5ae-4P5_LRU7jW91q7KoYYycgc9hTsNlZI13burJcONup4iwzRz_LAkdLeYmoS2uXZ1LCTJfs-wzIvfMr5TctnvUg1zv3FX7-YA.webp" className="rounded-full w-28 h-28"></img>
                    <span className="top-0 start-0 absolute w-3.5 h-3.5 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
                <div className="text-3xl font-bold mt-2">
                  Dev. K / 김태형
                </div>
                <span className="flex justify-center -ml-4">
                    <a href="#!" className="text-gray-500">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                    </a>
                    <a href="#!" className="ml-3 text-gray-500">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                    </a>
                    <a href="#!" className="ml-3 text-gray-500">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                    </a>
                    <a href="#!" className="ml-3 text-gray-500">
                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                        <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                        <circle cx="4" cy="4" r="2" stroke="none"></circle>
                    </svg>
                    </a>
                </span>
                <div className="md:mx-14 mx-2 font-normal text-gray-600">
                  <p className="my-3">안녕하세요! 제 블로그에 방문해 주셔서 감사합니다.</p>
                  <p className="my-3"> 저는 [자신의 직업 또는 역할]로 활동하며, 이 블로그는 [블로그 주제 또는 목적]에 대한 제 열정을 나누는 공간입니다. 현재 [경력]에서 [특정 분야 또는 역할]을 맡아 일하고 있습니다.</p>
                  <p className="my-3"> 이 블로그에서는 [블로그에서 다룰 주요 주제나 카테고리]에 관한 유용한 정보와 팁을 공유할 예정입니다. 블로그를 통해 유익한 정보를 얻고 소통하는 즐거운 시간이 되기를 바랍니다. 궁금한 점이나 의견이 있으면 언제든지 댓글로 남겨 주세요. 감사합니다!</p>
                </div>
              </div>
              <div className="lg:w-1/2 w-full md:py-6">
                <div className="flex relative pb-12">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" className="w-5 h-5" viewBox="0 0 15 15">
                      <path d="m7.5 4.5 4 2v8h-8v-8zm0 0V0M0 14.5h15m-13.5 0v-6h2m10 6v-6h-2m-5 6v-3h2v3m-1-14h3v2h-3m0 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">Dankook Univ. C.E. <span className="text-gray-400">2021.3 ~ </span></h2>
                    <p className="leading-relaxed text-sm">단국대학교 컴퓨터공학과에 재학하면서 기초적인 CS 지식에 대해서 학습하였습니다.</p>
                  </div>
                </div>

                <div className="flex relative pb-12">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="m24 12.42-4.428 4.415H24zm-4.428-4.417-4.414 4.418v4.414h4.414V12.42L24 8.003V3.575h-4.428zm-4.414 0 4.414-4.428h-4.414zM0 15.996h8.842v4.43h4.412V12.42H4.428l8.826-8.846H8.842L0 12.421z"/>
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">42 Seoul 6th Cadet <span className="text-gray-400">2022.3 ~ 2023.5 </span></h2>
                    <p className="leading-relaxed text-sm">42 Seoul 6th Cadet으로 활동하면서 C/C++로 다양한 프로젝트를 진행하고 동료 리뷰하는 활동을 하였습니다.</p>
                  </div>
                </div>

                <div className="flex relative pb-12">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" className="w-7 h-7" viewBox="0 0 64 64">
                      <path d="M41.236 22H54a2 2 0 1 1 0 4H43.236l2 4H46a8 8 0 0 1 0 16H16a8 8 0 0 1 0-16h.306l1.721-10.329A2 2 0 0 1 20 18h18a2 2 0 0 1 1.789 1.106zM16 34a4 4 0 0 0 0 8h30a4 4 0 0 0 0-8zm24.764-4-4-8h-15.07l-1.333 8z"/>
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">Military Service in the Republic of Korea Army <span className="text-gray-400">2023.5 ~ 2024.11 </span></h2>
                    <p className="leading-relaxed text-sm">대한민국 육군에서 1년 6개월간 복무하였습니다.</p>
                  </div>
                </div>

                <div className="flex relative pb-12">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="m24 12.42-4.428 4.415H24zm-4.428-4.417-4.414 4.418v4.414h4.414V12.42L24 8.003V3.575h-4.428zm-4.414 0 4.414-4.428h-4.414zM0 15.996h8.842v4.43h4.412V12.42H4.428l8.826-8.846H8.842L0 12.421z"/>
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">42 Seoul 6th Member <span className="text-gray-400">2024.12 ~ </span></h2>
                    <p className="leading-relaxed text-sm">42 Seoul 6th Member로 활동하면서 다양한 프로젝트를 진행하였습니다.</p>
                  </div>
                </div>

                <div className="flex relative">
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">LabLabLab Undergraduate Internship <span className="text-gray-400">2024.12 ~ </span></h2>
                    <p className="leading-relaxed text-sm">클라우드 분야 Lab에서 다양한 기술들을 연구하였습니다.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section ref={ref} className={`transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'} text-gray-600 body-font`}>
          <div className="container px-5 py-12 mx-auto">
            <div className="flex flex-col text-center w-full mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Programming Language Skill</h1>
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base">프로그래밍 언어에 대한 경험을 수치로 나타냅니다. 더 많은 칸이 표시되어있는 만큼 해당 언어에서 더 많은 경험을 했다고 할 수 있습니다.</p>
            </div>
            <div className={`flex flex-wrap -m-4 text-center`}>
              <LanguageSkillBox language="C" level={4} img_url="https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/programming%20languages/c.svg"></LanguageSkillBox>
              <LanguageSkillBox language="C++" level={3} img_url="https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/programming%20languages/c++.svg"></LanguageSkillBox>
              <LanguageSkillBox language="C#" level={1} img_url="https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/programming%20languages/c%23.svg"></LanguageSkillBox>
              <LanguageSkillBox language="Python" level={4} img_url="https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/programming%20languages/python.svg"></LanguageSkillBox>
              <LanguageSkillBox language="Java" level={2} img_url="https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/programming%20languages/java.svg"></LanguageSkillBox>
              <LanguageSkillBox language="JavaScript" level={2} img_url="https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/programming%20languages/javascript.svg"></LanguageSkillBox>
              <LanguageSkillBox language="TypeScript" level={1} img_url="https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/programming%20languages/typescript.svg"></LanguageSkillBox>            
              <LanguageSkillBox language="Bash" level={3} img_url="https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/programming%20languages/bash.svg"></LanguageSkillBox>
            </div>
          </div>
        </section>
        
        <section ref={ref2} className={`transition-opacity duration-1000 ${isVisible2 ? 'opacity-100' : 'opacity-0'} text-gray-600 body-font`}>
          <div className="container px-5 py-12 mx-auto">
            <div className="flex flex-col text-center w-full mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">SW Tech Skill</h1>
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base">SW 테크 스킬은 다양한 소프트웨어에 활용되는 프레임워크나 개발 테크 스킬을 나타냅니다.</p>
            </div>
            <div className="border-b border-gray-200 dark:border-gray-700">
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center justify-center text-gray-500 dark:text-gray-400">
                  <li onClick={() => {changeSkill('cloud')}} className="me-2 cursor-pointer">
                      <div className = {`${techSkill === 'cloud' ? 'text-blue-600 border-b-2 border-blue-600': 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-flex items-center p-4 border-b-2 rounded-t-lg`}>
                          <svg className={`${techSkill === 'cloud' ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-500'} w-5 h-5 me-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M18.42,9.21a7,7,0,0,0-13.36,1.9A4,4,0,0,0,6,19H17a5,5,0,0,0,1.42-9.79ZM17,17H6a2,2,0,0,1,0-4,1,1,0,0,0,1-1,5,5,0,0,1,9.73-1.61,1,1,0,0,0,.78.66A3,3,0,0,1,17,17Z"/>
                          </svg>Cloud
                      </div>
                  </li>
                  <li onClick={() => {changeSkill('database')}} className="me-2 cursor-pointer">
                      <div className = {`${techSkill === 'database' ? 'text-blue-600 border-b-2 border-blue-600': 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-flex items-center p-4 border-b-2 rounded-t-lg`}>
                          <svg className={`${techSkill === 'database' ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-500'} w-5 h-5 me-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M8 16.5a1 1 0 1 0 1 1 1 1 0 0 0-1-1M12 2C8 2 4 3.37 4 6v12c0 2.63 4 4 8 4s8-1.37 8-4V6c0-2.63-4-4-8-4m6 16c0 .71-2.28 2-6 2s-6-1.29-6-2v-3.27A13.16 13.16 0 0 0 12 16a13.16 13.16 0 0 0 6-1.27Zm0-6c0 .71-2.28 2-6 2s-6-1.29-6-2V8.73A13.16 13.16 0 0 0 12 10a13.16 13.16 0 0 0 6-1.27Zm-6-4C8.28 8 6 6.71 6 6s2.28-2 6-2 6 1.29 6 2-2.28 2-6 2m-4 2.5a1 1 0 1 0 1 1 1 1 0 0 0-1-1"/>
                          </svg>Database
                      </div>
                  </li>
                  <li onClick={() => {changeSkill('ci')}} className="me-2 cursor-pointer">
                      <div className = {`${techSkill === 'ci' ? 'text-blue-600 border-b-2 border-blue-600': 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-flex items-center p-4 border-b-2 rounded-t-lg`}>
                          <svg className={`${techSkill === 'ci' ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-500'} w-5 h-5 me-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 36 36">
                          <path d="M23.53 19.81a7.5 7.5 0 0 1-1.65-.18 10.5 10.5 0 0 1 .72 2.13h.93a9.5 9.5 0 0 0 3-.49l-.93-1.81a7.7 7.7 0 0 1-2.07.35m-5.17-1.94-.36-.38a7.4 7.4 0 0 1-2.2-5.92 7.3 7.3 0 0 1 1.54-4L17.26 9a1 1 0 0 0 .91 1h.09a1 1 0 0 0 1-.91L19.6 5a1 1 0 0 0-.29-.79 1 1 0 0 0-.79-.21l-4.09.35a1 1 0 0 0 .17 2l1.29-.11a9.45 9.45 0 0 0-2.05 5.32 9.28 9.28 0 0 0 2.67 7.26l.31.37a7.33 7.33 0 0 1 2.06 4.91 7.4 7.4 0 0 1-.26 2.47l1.8.91a8.8 8.8 0 0 0 .45-3.51 9.28 9.28 0 0 0-2.51-6.1m14.04.04-1.21.09a9.65 9.65 0 0 0-7.66-15.55 9.3 9.3 0 0 0-3 .49l.91 1.8a7.67 7.67 0 0 1 9.76 7.39 7.58 7.58 0 0 1-1.65 4.72l.1-1.54a1 1 0 1 0-2-.13l-.28 4.08a1 1 0 0 0 .31.78.94.94 0 0 0 .69.28h.1l4.08-.42a1 1 0 0 0 .9-1.1 1 1 0 0 0-1.05-.89M4.07 20.44h.08l4.09-.35a1 1 0 1 0-.17-2l-1.39.12a7.63 7.63 0 0 1 4.52-1.49 8 8 0 0 1 1.63.18 10.2 10.2 0 0 1-.71-2.13h-.92a9.66 9.66 0 0 0-5.9 2l.12-1.31a1 1 0 0 0-.92-1.08 1 1 0 0 0-1.08.91l-.35 4.08a1 1 0 0 0 1 1.08Zm14.35 7.79-4.09.27a1 1 0 0 0 .13 2l1.54-.11a7.71 7.71 0 0 1-12.54-6 7.6 7.6 0 0 1 .29-2L2 21.46a9.6 9.6 0 0 0-.47 2.95A9.7 9.7 0 0 0 17.19 32l-.12 1.18a1 1 0 0 0 .89 1.1h.11a1 1 0 0 0 1-.9l.42-4.06a1 1 0 0 0-1.06-1.1Z" data-name="Layer 3"/>
                          </svg>CI/CD
                      </div>
                  </li>
                  <li onClick={() => {changeSkill('collaborative')}} className="me-2 cursor-pointer">
                      <div className = {`${techSkill === 'collaborative' ? 'text-blue-600 border-b-2 border-blue-600': 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-flex items-center p-4 border-b-2 rounded-t-lg`}>
                          <svg className={`${techSkill === 'collaborative' ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-500'} w-5 h-5 me-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32">
                          <path d="M6 21v-1H4v1a7 7 0 0 0 7 7h3v-2h-3a5 5 0 0 1-5-5m18-10v1h2v-1a7 7 0 0 0-7-7h-3v2h3a5 5 0 0 1 5 5m-13 0H5a3 3 0 0 0-3 3v2h2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h2v-2a3 3 0 0 0-3-3m-3-1a4 4 0 1 0-4-4 4 4 0 0 0 4 4m0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2m19 21h-6a3 3 0 0 0-3 3v2h2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h2v-2a3 3 0 0 0-3-3m-7-5a4 4 0 1 0 4-4 4 4 0 0 0-4 4m6 0a2 2 0 1 1-2-2 2 2 0 0 1 2 2"/><path data-name="&lt;Transparent Rectangle&gt;" fill="none" d="M0 0h32v32H0z"/>
                          </svg>Collaborative
                      </div>
                  </li>
                  <li onClick={() => {changeSkill('ai')}} className="me-2 cursor-pointer">
                      <div className = {`${techSkill === 'ai' ? 'text-blue-600 border-b-2 border-blue-600': 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-flex items-center p-4 border-b-2 rounded-t-lg`}>
                          <svg className={`${techSkill === 'ai' ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-500'} w-5 h-5 me-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M10.25 12.33a.85.85 0 0 1-.35-.08L7.44 11 5 12.25a.75.75 0 0 1-1.09-.8l.47-2.73-2-1.94a.76.76 0 0 1-.2-.78.75.75 0 0 1 .61-.51l2.75-.4 1.22-2.48a.79.79 0 0 1 1.35 0L9.34 5.1l2.75.4a.75.75 0 0 1 .41 1.28l-2 1.94.5 2.73a.75.75 0 0 1-.29.74.8.8 0 0 1-.46.14M4.51 6.76l1.18 1.16a.74.74 0 0 1 .22.66l-.28 1.63 1.46-.77a.78.78 0 0 1 .7 0l1.46.77L9 8.58a.74.74 0 0 1 .22-.66l1.18-1.16-1.67-.23a.76.76 0 0 1-.56-.41l-.73-1.49-.73 1.49a.76.76 0 0 1-.57.41zm7.82 15.05a.8.8 0 0 1-.44-.14.76.76 0 0 1-.3-.74l.58-3.34-2.43-2.37a.73.73 0 0 1-.19-.77.75.75 0 0 1 .6-.51l3.36-.48L15 10.41a.79.79 0 0 1 1.35 0l1.5 3.05 3.35.48a.75.75 0 0 1 .42 1.28l-2.42 2.37.57 3.34a.76.76 0 0 1-.29.74.73.73 0 0 1-.79 0l-3-1.57-3 1.57a.73.73 0 0 1-.36.14m-.46-6.6 1.63 1.58a.77.77 0 0 1 .21.67l-.38 2.23 2-1.06a.78.78 0 0 1 .7 0l2 1.06-.38-2.23a.72.72 0 0 1 .21-.67l1.62-1.58-2.24-.33a.72.72 0 0 1-.56-.41l-1-2-1 2a.72.72 0 0 1-.56.41z"/>
                          </svg>AI/ML/DL
                      </div>
                  </li>
                  <li onClick={() => {changeSkill('backend')}} className="me-2 cursor-pointer">
                      <div className = {`${techSkill === 'backend' ? 'text-blue-600 border-b-2 border-blue-600': 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-flex items-center p-4 border-b-2 rounded-t-lg`}>
                          <svg className={`${techSkill === 'backend' ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-500'} w-5 h-5 me-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 1024 1024">
                          <path d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357 357 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a352 352 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357 357 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294 294 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293 293 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294 294 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288 288 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293 293 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a288 288 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384m0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256"/>
                          </svg>Backend
                      </div>
                  </li>
                  <li onClick={() => {changeSkill('frontend')}} className="me-2 cursor-pointer">
                      <div className = {`${techSkill === 'frontend' ? 'text-blue-600 border-b-2 border-blue-600': 'hover:text-gray-600 hover:border-gray-300 border-transparent'} inline-flex items-center p-4 border-b-2 rounded-t-lg`}>
                          <svg className={`${techSkill === 'frontend' ? 'text-blue-600' : 'text-gray-400 group-hover:text-gray-500'} w-5 h-5 me-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m-5 14H4v-4h11zm0-5H4V9h11zm5 5h-4V9h4z"/>
                          </svg>Frontend
                      </div>
                  </li>
              </ul>
          </div>
            <div className={`${visible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 ease-in-out flex flex-wrap -m-4 text-center min-h-[600px] mt-5`}>
              {listSkill.map((skill) => { return (
                <div className="p-4 md:w-1/4 sm:w-1/2 w-full hover:scale-105 transition-transform duration-300 ease-in-out">
                  <div className="border-2 border-gray-200 px-4 py-6 rounded-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
                    <p className="title-font font-medium text-sm -mt-5 mb-1 text-gray-600">{skill.skill}</p>
                    <img alt={skill.name} className="inline-block w-24 h-24" height={skill.height} width={skill.width} src={skill.img}></img>
                      <h2 className="title-font font-medium text-3xl text-gray-900">{skill.name}</h2>
                      <SkillLevel level={skill.level}/>
                  </div>
                </div>
              )})}
            </div>
          </div>
        </section>
      </div>
      <Footer/>
    </motion.div>
  );
}
