import React, { useState } from 'react'
import {refreshAPI} from './TokenRefresher.tsx';

interface category {
    cid : number,
    cname : string,
}

export function CategorySelector({Name, Category, setCategory}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [selections, setSelections] = useState<category[]>([]);
    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);
    const [length, setLength] = useState(0);

    const handleDescriptionChange = (e) => {
        setLength(e.target.value.length);
    }

    const handleFile1Change = (e) => {
        setFile1(Array.from(e.target.files));
    }

    const handleFile2Change = (e) => {
        setFile2(Array.from(e.target.files));
    }

    const toggleDropdown = async () => {
        await loadCategory();
        setIsOpen(!isOpen);
    };

    const handleChange = (value : string) => {
      setCategory(value);
    };

    const closeDropdown = (value : string) => {
        handleChange(value)
        setIsOpen(false);
    };
    
    const addDropdown = () => {
        setIsModal(true);
    };

    async function loadCategory() {
        try {
            const res = await refreshAPI.get('https://api.taehyeok.o-r.kr/category/');
            setSelections(res.data.results);
        } catch (error) {
            alert("Get Category Fail");
        }
    }

    async function addCategory() {
        const category = (document.getElementById('category') as HTMLInputElement).value;
        const Description = (document.getElementById('message') as HTMLInputElement).value;
        var data = {
            "cname" : category,
            "description" : Description,
        };
        if (category === '' || Description === '') {
            console.log(category, Description)
            alert('모든 칸을 채워주세요');
            return ;
        }
        const formData1 = new FormData();
        file1.forEach((file) => {
            formData1.append("file", file);
          });
        formData1.append("filename", category + '_default_thumb');
        try {
            const res = await refreshAPI.post('https://api.taehyeok.o-r.kr/upload/', formData1, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
            data['default_thumb'] = res.data['file_url'];
            console.log(res.data['file_url']);
        }catch (err){
            console.log(err);
            alert('Thumbnail Upload Error');
            return ;
        }
        const formData2 = new FormData(); 
        file2.forEach((file) => {
            formData2.append("file", file);
        });
        
        formData2.append("filename", category + '_default_svg');
        try {
            const res = await refreshAPI.post('https://api.taehyeok.o-r.kr/upload/', formData2, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
              data['default_svg'] = res.data['file_url'];
            console.log(res.data['file_url']);
        }catch (err){
            alert('SVG Upload Error');
            return ;
        }
        try {
            await refreshAPI.post('https://api.taehyeok.o-r.kr/category/', data);
            closeModal();
            closeDropdown(category);
        }
        catch (error) {
            alert("Add Category Fail");
            closeModal();
        }
    };
    
    const closeModal = () => {
        setIsModal(false);
    };

    return (
       <div>
            <div className='block'>
                <label htmlFor={Name} className="block mb-2 text-sm font-medium text-gray-900">{Name}</label>
                <div className='flex '>
                    <input type="button" id={Name} onClick={toggleDropdown} aria-label={Name} value={Category} className="text-left pl-7 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"    />
                    <label htmlFor={Name} className='absolute pt-4'>
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                        </svg>
                    </label>
                </div>

                {isOpen && (
                <div id="dropdownUsers" className="absolute z-10 bg-white rounded-lg shadow w-60">
                <ul className="h-48 py-2 overflow-y-auto text-gray-700 ">
                    {selections.map(({cname, cid} : category) => 
                        <li key={cid}> 
                            <a href='#!' onClick={() => closeDropdown(cname)}  className="flex items-center px-4 py-2 hover:bg-gray-100">
                            {cname}
                            </a>
                        </li>)}
                </ul>
                <a href="#!" onClick={addDropdown} className="flex items-center p-3 text-sm font-medium text-blue-600 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100 hover:underline">
                    <svg className="w-4 h-4 me-2" aria-hidden="true" version="1.0" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20.000000pt" height="18.000000pt" viewBox="0 0 512.000000 512.000000">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                        <path d="M2434 4005 c-34 -7 -84 -25 -112 -39 -92 -47 -87 -12 -90 -573 l-3
                        -493 -485 0 c-532 0 -531 0 -574 -60 -12 -17 -31 -60 -42 -93 -55 -178 -15
                        -420 82 -484 l33 -23 498 0 499 0 0 -505 0 -504 31 -35 c38 -44 107 -70 227
                        -86 149 -19 301 17 361 86 l31 35 0 504 0 505 494 0 493 0 33 23 c69 46 116
                        198 107 343 -6 88 -36 191 -69 237 -41 57 -44 57 -573 57 l-485 0 0 494 0 494
                        -27 33 c-36 42 -96 68 -202 85 -103 17 -147 17 -227 -1z"/>
                        </g>
                    </svg>
                    Add Category
                </a>
                </div> )
            }
            {isModal && (
            <div id="crud-modal" tabIndex={-1} aria-hidden="true" className="flex bg-black bg-opacity-70 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Create New Category
                                </h3>
                                <button type="button" onClick={closeModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-4 md:p-5">
                                <div className="grid gap-4 mb-4 grid-cols-2">
                                    <div className="col-span-2">
                                        <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Category</label>
                                        <input type="text" name="category" id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type category name" required/>
                                    </div>
                                    <div className="col-span-2">
                                        <label htmlFor="file-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category Thumbnail Picture</label>
                                        <input type="file" name="file-input" id="file-input" className="block w-full border bg-gray-50 border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
                                            file:bg-blue-700 file:border-0
                                            file:me-4
                                            file:py-2 file:px-4
                                            file:text-white
                                        " onChange={handleFile1Change}/>
                                    </div>
                                    <div className="col-span-2">
                                        <label htmlFor="file-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category SVG Icon</label>
                                        <input type="file" name="file-input" id="file-input" className="block w-full border bg-gray-50 border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
                                            file:bg-blue-700 file:border-0
                                            file:me-4
                                            file:py-2 file:px-4
                                            file:text-white
                                        " onChange={handleFile2Change}/>
                                    </div>
                                    <div className='col-span-2'>
                                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                        <textarea onKeyUp={handleDescriptionChange} maxLength={75} id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
                                        <div className='flex'>
                                            <p className={`ml-auto text-sm font-sans ${length >= 75 ? 'text-red-600': '' }`}>{length}/75</p>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" onClick={addCategory} className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                    Add new category
                                </button>
                            </div>
                        </div>
                    </div>
                </div> 
            )}
            </div>
       </div> 
    );
}