import Footer from "../components/footer.tsx";
import axios from "axios";
import React, { useRef } from 'react'
import { useForm, SubmitHandler } from "react-hook-form";

interface FormValue {
	nickname: string
  	email: string
  	password: string
  	confirm_password: string
    chkbox : boolean
}

export default function Signin() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormValue>()

    const onSubmit: SubmitHandler<FormValue> = (data) => {
        axios.post(
            "https://api.taehyeok.o-r.kr/register/",
            {
                "nickname" : data.nickname,
                "email" : data.email,
                "password" : data.password
            },
        ).then((res) => {window.location.href = '/'})
        .catch((error) => console.log(error))
    }
    const passwordRef = useRef<string | null>(null)
    passwordRef.current = watch("password")

    return (
        <div>
            <section className="bg-gray-50 dark:bg-gray-900">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <a href="#!" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white gap-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
                            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
                        </svg>
                        Dev.K    
                    </a>
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Create an account
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input {...register("email", { required: true, pattern: /^([a-z0-9_.-]+)@([\da-z.-]+).([a-z.]{2,6})$/ })} type="text" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com"/>
                                    {errors.email && errors.email.type === "required" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">이메일을 입력해 주세요!</div>
                                    )}
                                    {errors.email && errors.email.type === "pattern" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">이메일을 형식을 확인해주세요!</div>
                                    )}
                                </div>
                                <div>
                                    <label htmlFor="nickname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Nickname</label>
                                    <input {...register("nickname", { required: true, maxLength: 50 })} type="text" name="nickname" id="nickname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nickname"/>
                                    {errors.nickname && errors.nickname.type === "required" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">이름을 입력해 주세요!</div>
                                    )}
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input {...register("password", { required: true, minLength: 6 })} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                                    {errors.password && errors.password.type === "required" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">비밀번호를 입력해 주세요!</div>
                                    )}
                                    {errors.password && errors.password.type === "minLength" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">비밀번호가 너무 짧아요! 6자 이상으로 설정해주세요!</div>
                                    )}
                                </div>
                                <div>
                                    <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                                    <input {...register("confirm_password", {
          		                            required: true,
            	                            validate: (value : string) => value === passwordRef.current,
          	                                                                })} type="password" name="confirm_password" id="confirm_password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>                              
                                    {errors.confirm_password && errors.confirm_password.type === "required" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">비밀번호를 한번 더 입력해 주세요!</div>
                                    )}
                                    {errors.confirm_password && errors.confirm_password.type === "validate" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">비밀번호가 동일하지 않아요!</div>
                                    )}
                                </div>
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input {...register("chkbox", {required : true})} id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"/>
                                        {errors.chkbox && errors.chkbox.type === "required" && (
                                            <div className="absolute text-xs font-semibold text-red-500 mt-10">여기를 체크해주세요!</div>
                                        )}
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-blue-600 hover:underline dark:text-blue-500" href="#!">Terms and Conditions</a></label>
                                    </div>
                                </div>
                                <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create an account</button>
                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Already have an account? <a href="/login" className="font-medium text-blue-600 hover:underline dark:text-blue-500">Login here</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                </section>
            <Footer/>
        </div>
    );
}