import React from "react";
import Develop from "../components/develop.tsx";
import Footer from "../components/footer.tsx";
import { motion } from "framer-motion";

 
function Projects() {
  return (
    <motion.div
    className="bg-white"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{
        duration : 0.5,
        ease : 'easeInOut'
    }}
  >
        <div className="min-h-screen">
            <Develop></Develop>
        </div>
        <Footer></Footer>
    </motion.div>

  );
}
 
export default Projects;
