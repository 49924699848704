import Footer from "../components/footer.tsx";
import axios from "axios";
import React from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { jwtDecode } from 'jwt-decode';

interface FormValue {
	email : string
  password : string
}

export default function Login() {
  const {register, handleSubmit, formState : {errors}} = useForm<FormValue>()

  const onSubmit : SubmitHandler<FormValue> = (data) => {
    axios.post(
      "https://api.taehyeok.o-r.kr/login/",
      {
        "email" : data.email,
        "password" : data.password
      },
      {
        withCredentials: true
      }
    ).then((res) => {
      const decodedToken = jwtDecode(res.data['token']['refresh']);
      const expiryTime = decodedToken.exp;

      localStorage.setItem('isLogin', 'true');
      localStorage.setItem('nickname', res.data['nickname']);
      localStorage.setItem('email', res.data['user']);
      localStorage.setItem('expiryTime', expiryTime.toString());
      window.location.href = '/';
    })
    .catch((error) => console.log(error))
  } 

  return (
    <main>
      <div className="bg-gray-50">
        <section className="bg-gray-50 ">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
              <a href="#!" className="hidden md:flex items-center mb-6 text-2xl font-semibold text-gray-900 gap-3 select-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
                  <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
                </svg>
                  Dev.K    
              </a>
              <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                          Sign in to your account
                      </h1>
                      <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                          <div>
                              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                              <input {...register("email", {required: true})} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="name@company.com"/>
                              {errors.email && errors.email.type === "required" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">이메일을 입력해 주세요!</div>
                                    )}
                          </div>
                          <div>
                              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
                              <input {...register("password", {required: true})}type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "/>
                              {errors.password && errors.password.type === "required" && (
                                        <div className="text-xs font-semibold text-red-500 -mb-4">비밀번호를 입력해 주세요!</div>
                                    )}
                          </div>
                          <div className="flex items-center justify-between">
                              <div className="flex items-start">
                                  <div className="flex items-center h-5">
                                    <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 "/>
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label htmlFor="remember" className="text-gray-500 select-none">Remember me</label>
                                  </div>
                              </div>
                              <a href="#!" className="text-sm font-medium text-blue-600 hover:underline ">Forgot password?</a>
                          </div>
                          <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign in</button>
                          <p className="text-sm font-light text-gray-500 ">
                              Don’t have an account yet? <a href="/signup" className="font-medium text-blue-600 hover:underline ">Sign up</a>
                          </p>
                      </form>
                  </div>
              </div>
          </div>
        </section>
        <Footer/>
      </div>
    </main>
  );
}
