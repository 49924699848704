import React from "react";
import SkillLevel from "./SkillLevel.tsx";

export default function LanguageSkillBox({level, img_url, language} : {level : number, img_url : string, language : string}) {
    return (
        <div className="p-4 md:w-1/4 sm:w-1/2 w-full hover:scale-105 transition-transform duration-300 ease-in-out">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <img alt={language} className="inline-block" width={80} src={img_url}></img>
                <h2 className="title-font font-medium text-3xl text-gray-900">{language}</h2>
                <SkillLevel level={level}/>
            </div>
        </div>
    );
}