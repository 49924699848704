import Footer from '../components/footer.tsx';
import DisableInputBox from '../components/disableinputbox.tsx';
import Inputbox from '../components/inputbox.tsx';
import { CategorySelector } from '../components/CategorySelector.tsx';
import { ToggleSwitch } from '../components/ToggleSwitch.tsx';
import { HashTagSelector } from '../components/HashTagSelector.tsx';
import { useEffect, useRef, useState } from 'react';
import { refreshAPI } from '../components/TokenRefresher.tsx';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import React from 'react';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';
import { NodeHtmlMarkdown } from 'node-html-markdown';
import hljs from 'highlight.js';

declare global {
    interface Window {
      MathJax: any;
    }
  }

interface post {
    content : any,
    category : string,
    pname : string,
    slug : string,
    pthumb : string,
    pthumbname : string,
    updated : string,
    visible : boolean
}

interface posthashtag {
    pid: number,
    hashtagId: {
        hashtagId: number,
        hashtagName: string
    }
}

export default function EditPost() {

    const [success_toast, setsuccess_toast] = useState(false);
    const [fail_toast, setfail_toast] = useState(false);
    const [tags, setTags] = useState<string[]>([]);

    const handleTagsChange = (newTags: string[]) => {
        setTags(newTags);
      };
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const [UserName, setUserName] = useState('');
    const [created, setCreated] = useState('');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState('');
    const [liveView, setliveView] = useState(false);
    const [isModal, setisModal] = useState(false);
    const [file, setfile] = useState([]);
    const params = useParams();

    const closeModal = () => {
        setisModal(false);
    }
    const handleFileChange = (e) => {
        setfile(Array.from(e.target.files));
    }
    const uploadThumbnail = async () => {
        const formData = new FormData();
        file.forEach((file) => {
            formData.append("file", file);
          });
        formData.append("filename", 'thumbnailIMG');
        try {
            const res = await refreshAPI.post('https://api.taehyeok.o-r.kr/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // 필요에 따라 'Content-Type'을 'multipart/form-data'로 설정
                }
            });
            (document.getElementById("ThumbNail URL") as HTMLInputElement).value = res.data['file_url'];
        } catch (err) {
            console.log(err);
            alert('Upload Fail');
        }
        setisModal(false);
    }

    const previewClassName = liveView 
    ? 'block' 
    : 'hidden';

    const tinyMCEClassName = liveView 
    ? 'basis-1/2' 
    : 'basis-full';
    const image_upload_handler = (blobInfo, progress) => {
        return new Promise<string>((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            formData.append('filename', blobInfo.filename());
    
            refreshAPI.post('https://api.taehyeok.o-r.kr/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // 필요에 따라 'Content-Type'을 'multipart/form-data'로 설정
                },
                onUploadProgress: (e) => {
                    if (e.lengthComputable && e.total) {
                        progress(e.loaded / e.total * 100); // 업로드 진행 상황을 퍼센트로 변환하여 전달
                    }
                }
            })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    reject('HTTP Error: ' + response.status);
                    return;
                }
    
                const json = response.data;
    
                if (!json || typeof json.location !== 'string') {
                    reject('Invalid JSON: ' + JSON.stringify(json));
                    return;
                }
    
                resolve(json.location);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        reject({ message: 'HTTP Error: ' + error.response.status, remove: true });
                    } else {
                        reject('HTTP Error: ' + error.response.status);
                    }
                } else if (error.request) {
                    reject('Image upload failed due to a network error.');
                } else {
                    reject('Image upload failed: ' + error.message);
                }
            });
        });
    };
    useEffect(() => {
        const getContent = async () => {
            try {
                const res = await refreshAPI.get('https://api.taehyeok.o-r.kr/post/' + params.id + '/');
                const hash = await refreshAPI.get('https://api.taehyeok.o-r.kr/posthashtag/?pid=' + params.id);
                try {
                    var hashs = ['']
                    console.log(hash);
                    hash.data.results.forEach((data : posthashtag)=> {
                        hashs = [...hashs, data.hashtagId.hashtagName]
                    });
                    setTags(hashs.slice(1));
                } catch (err) {
                    console.log(err);
                }
                setContent(res.data['content']);
                setUserName(res.data['uid']['nickname']);
                setCreated(res.data['created']);
                setCategory(res.data['cid']['cname']);
                (document.getElementById("ThumbNail URL") as HTMLInputElement).value = res.data['pthumb'];
                (document.getElementById("ThumbNail Name") as HTMLInputElement).value = res.data['pthumbname'];
                (document.getElementById("Title") as HTMLInputElement).value = res.data['pname'];
                (document.getElementById("Slug") as HTMLInputElement).value = res.data['slug'];
                (document.getElementById('visible') as HTMLInputElement).checked = res.data['visible'];
                
            } catch(err) {
                alert(err);
            }
        }
        getContent();
    }, [params.id])

    // 5분에 한번 자동 저장
    useEffect(() => {
        if (category.length) {
            const intervalId = setInterval(async () => {
            const curDate = new Date().toLocaleString();
            const content = editorRef.current && editorRef.current.getContent();
            const thumbInput = (document.getElementById("ThumbNail URL") as HTMLInputElement).value;
            const thumbNInput = (document.getElementById("ThumbNail Name") as HTMLInputElement).value;
            const titleInput = (document.getElementById("Title") as HTMLInputElement).value;
            const slugInput = (document.getElementById("Slug") as HTMLInputElement).value;
            const visible = (document.getElementById('visible') as HTMLInputElement).checked;
            console.log(category, category.length);
            if (!category.length) {
                setfail_toast(true);
                return false;
            }
            if (!titleInput) {
                setfail_toast(true);
                return false;
            }
            var data : post = {
                content : content,
                category : category,
                pname : titleInput,
                slug : slugInput,
                pthumb : thumbInput,
                pthumbname : thumbNInput,
                updated : curDate,
                visible : visible
            }
            try {
                await refreshAPI.patch('https://api.taehyeok.o-r.kr/post/' + params.id + '/', data);
                if (tags.length) {
                    try {
                        await refreshAPI.post('https://api.taehyeok.o-r.kr/posthashtag/replace_tags/', {
                            pid : params.id,
                            hashtags : tags
                        });
                    }
                    catch (err) {
                        console.log(err);
                        alert ("Add Hashtag Fail");
                    }
                }
                setsuccess_toast(true);
            } catch {
                setfail_toast(true);
            }
            }, 300000);            
            return () => clearInterval(intervalId);
        }
    }, [category, tags, params.id])

    const postSubmit = async () => {
        const curDate = new Date().toLocaleString();
        const content = editorRef.current && editorRef.current.getContent();
        const thumbInput = (document.getElementById("ThumbNail URL") as HTMLInputElement).value;
        const thumbNInput = (document.getElementById("ThumbNail Name") as HTMLInputElement).value;
        const titleInput = (document.getElementById("Title") as HTMLInputElement).value;
        const slugInput = (document.getElementById("Slug") as HTMLInputElement).value;
        const visible = (document.getElementById('visible') as HTMLInputElement).checked;
        console.log(category, category.length);
        if (!category.length) {
            alert('Category 채워주세요')
            document.getElementById("Category")?.focus();
            return false;
        }
        if (!titleInput) {
            alert('Fill Title');
            document.getElementById("Title")?.focus();
            return false;
        }
        if (!(document.getElementById('check') as HTMLInputElement).checked) {
            alert('Check');
            document.getElementById("check")?.focus();
            return false;
        }
        var data : post = {
            content : content,
            category : category,
            pname : titleInput,
            slug : slugInput,
            pthumb : thumbInput,
            pthumbname : thumbNInput,
            updated : curDate,
            visible : visible
        }
        try {
            await refreshAPI.patch('https://api.taehyeok.o-r.kr/post/' + params.id + '/', data);
            if (tags.length) {
                try {
                    await refreshAPI.post('https://api.taehyeok.o-r.kr/posthashtag/replace_tags/', {
                        pid : params.id,
                        hashtags : tags
                    });
                }
                catch (err) {
                    console.log(err);
                    alert ("Add Hashtag Fail");
                }
            }
            alert("post Edit success");
            window.location.href = '/posts/' + params.id + '/';
        } catch {
            alert("post Edit Fail");
        }
        return true;
    };
 return (
    <div className='bg-white'>
        <div id="success_toast-success" className={`${success_toast ? 'opacity-100 z-50' : 'opacity-0 z-0'} transition-opacity duration-300 ease-in-out fixed left-3/4 top-10 flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`} role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="sr-only">Check icon</span>
            </div>
            <div className="ms-3 text-sm font-normal">Post AutoSaved successfully.</div>
            <button type="button" onClick={() => setsuccess_toast(false)} className={`${success_toast ? 'block' : 'hidden'} ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700`} data-dismiss-target="#success_toast-success" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
            </button>
        </div>
        <div id="toast-danger" className={`${fail_toast ? 'opacity-100 z-50' : 'opacity-0 z-0'} transition-opacity duration-300 ease-in-out fixed left-3/4 top-10 flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`} role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                </svg>
                <span className="sr-only">Error icon</span>
            </div>
            <div className="ms-3 text-sm font-normal">Post AutoSaving is failed.</div>
            <button type="button" onClick={() => setfail_toast(false)} className={`${fail_toast ? 'block' : 'hidden'} ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700`} data-dismiss-target="#toast-danger" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
            </button>
        </div>
        <section className="mx-0 md:mx-24 lg:mx-48 text-gray-600 pt-12 body-font">
          <div className="container px-5 mx-auto">
            <div className="flex flex-wrap w-full mb-10">
              <div className="lg:w-1/2 w-full lg:mb-0">
                <h1 className="text-3xl font-medium title-font mb-2 text-gray-900">Meta Data</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
              </div>
            </div>
          </div>
        </section>
        <form>
            <div className='mx-0 md:mx-24 lg:mx-48'>
                <div className="grid px-5 gap-6 mb-6 md:grid-cols-2">
                    <DisableInputBox Name="UserName" Value={UserName}/>
                    <DisableInputBox Name="Created" Value={created}/>
                    <CategorySelector Name="Category" Category={category} setCategory={setCategory}/>
                    <HashTagSelector Name="HashTag" tags={tags} onTagsChange={handleTagsChange}/>
                    <div className='flex'>
                        <div className='grow mr-2'>
                            <Inputbox Name="ThumbNail URL" Value="ThumbNail URL" requied={false}/>
                        </div>
                        <div className='ml-auto mt-7'>
                            <button onClick={() => {setisModal(true);}}type="button" className="mt-0.5 text-white border-2 border-gray-300 bg-gray-100 hover:bg-blue-300 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
                            <svg className="w-5 h-5" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin">
                            <path d="M8 3.414v5.642a1 1 0 1 1-2 0V3.414L4.879 4.536A1 1 0 0 1 3.464 3.12L6.293.293a1 1 0 0 1 1.414 0l2.829 2.828A1 1 0 1 1 9.12 4.536zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2"/>
                            </svg>
                            <span className="sr-only">Icon description</span>
                            </button>
                        </div>
                        {isModal && (
                        <div id="crud-modal" tabIndex={-1} aria-hidden="true" className="flex bg-black bg-opacity-70 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                            <div className="relative p-4 w-full max-w-md max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                            Create New Category
                                        </h3>
                                        <button type="button" onClick={closeModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                    <div className="p-4 md:p-5">
                                        <div className="grid gap-4 mb-4 grid-cols-2">
                                            <div className="col-span-2">
                                                <label htmlFor="file-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Thumbnail Picture</label>
                                                <input type="file" name="file-input" id="file-input" className="block w-full border bg-gray-50 border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
                                                    file:bg-blue-700 file:border-0
                                                    file:me-4
                                                    file:py-2 file:px-4
                                                    file:text-white
                                                " onChange={handleFileChange}/>
                                            </div>
                                        </div>
                                        <button type="button" onClick={uploadThumbnail} className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            <svg className="me-1 -ms-1 mt-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 3.414v5.642a1 1 0 1 1-2 0V3.414L4.879 4.536A1 1 0 0 1 3.464 3.12L6.293.293a1 1 0 0 1 1.414 0l2.829 2.828A1 1 0 1 1 9.12 4.536zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2"/>
                                            </svg>
                                            Upload Image
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                    <Inputbox Name="ThumbNail Name" Value="ThumbNail Name" requied={false}/>
                </div>
                <div className='flex flex-col px-5 mb-3 gap-6'>
                    <Inputbox Name="Title" Value="Title" requied={true}/>
                    <Inputbox Name="Slug" Value="Slug" requied={false}/>
                </div>
                <div className=" text-gray-600 pt-12 body-font">
                    <div className="container px-5 mx-auto">
                        <div className="flex flex-wrap w-full mb-10">
                        <div className="lg:w-1/2 w-full lg:mb-0">
                            <h1 className="text-3xl font-medium title-font mb-2 text-gray-900">Content</h1>
                            <div className="h-1 w-20 bg-indigo-500 rounded"></div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='flex px-5 mx-auto gap-5'>
                    <div className={tinyMCEClassName}>
                        <Editor
                            tinymceScriptSrc='/tinymce/tinymce.min.js'
                            licenseKey='gpl'
                            onInit={(_evt, editor) => 
                                {
                                    editorRef.current = editor
                                    editor.ui.registry.addButton('checkbox', {
                                        icon : 'preview',
                                        onAction: async function () {
                                            document.getElementById('preview')!.innerHTML = editor.getContent();
                                            window?.MathJax.typeset();
                                            hljs.highlightAll();
                                            setliveView(prevState => !prevState);
                                        }
                                      });
                                    
                                    var insertActions = [
                                        {
                                            text : 'Heading 1',
                                            icon : 'h1',
                                            action : function () {
                                                editor.execCommand('mceInsertContent', false, '<h1>Heading 1</h1>');
                                                editor.selection.select(editor.selection.getNode());
                                            }
                                        },
                                        {
                                            text : 'Heading 2',
                                            icon : 'h2',
                                            action : function () {
                                                editor.execCommand('mceInsertContent', false, '<h2>Heading 2</h2>');
                                                editor.selection.select(editor.selection.getNode());
                                            }
                                        },
                                        
                                        {
                                            text : 'Heading 3',
                                            icon : 'h3',
                                            action : function () {
                                                editor.execCommand('mceInsertContent', false, '<h3>Heading 3</h3>');
                                                editor.selection.select(editor.selection.getNode());
                                            }
                                        },
                                        {
                                        type: 'separator'
                                        },
                                        {
                                            text : 'Divider',
                                            icon : 'hr',
                                            action : function () {
                                                editor.execCommand('mceInsertContent', false, '<hr>');
                                            }
                                        },
                                        {
                                            text : 'BlockQuote',
                                            icon : 'quote',
                                            action : function () {
                                                editor.execCommand('mceBlockQuote');
                                            }
                                        },
                                        {
                                            text : 'CodeSample',
                                            icon : 'sourcecode',
                                            action : function () {
                                                editor.execCommand('CodeSample');
                                            }
                                        },
                                    ];

                                    editor.ui.registry.addAutocompleter('slashcommands', 
                                        {
                                            trigger : '/',
                                            minChars : 0,
                                            columns:1,
                                            fetch: function (pattern) {
                                                const matchedActions = insertActions.filter(function (action) {
                                                    return action.type === 'separator' ||
                                                        action?.text?.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
                                                });
                                    
                                                return new Promise((resolve) => {
                                                    var results = matchedActions.map(function (action) {
                                                        return {
                                                            meta: action,
                                                            text: action.text,
                                                            icon: action.icon,
                                                            value: action.text
                                                        }
                                                    });
                                                    resolve(results);
                                                });
                                            },
                                            onAction: function (autocompleteApi, rng, action, meta) {
                                                editor.selection.setRng(rng);
                                                // Some actions don't delete the "slash", so we delete all the slash
                                                // command content before performing the action
                                                editor.execCommand('Delete');
                                                meta.action();
                                                autocompleteApi.hide(); 
                                            }
                                        });
                                        return {};
                                }
                            }
                            initialValue={content}
                            init={{
                            height: 1000,
                            width : '100%',
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'image', 'codesample'
                            ],
                            toolbar: 'checkbox undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help MarkdownUploadButton MarkdownDownloadButton',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            codesample_languages: [
                                { text: 'HTML/XML', value: 'markup' },
                                { text: 'JavaScript', value: 'javascript' },
                                { text: 'JavaScript', value: 'javascript' },
                                { text: 'CSS', value: 'css' },
                                { text: 'PHP', value: 'php' },
                                { text: 'Ruby', value: 'ruby' },
                                { text: 'Python', value: 'python' },
                                { text: 'Java', value: 'java' },
                                { text: 'C', value: 'c' },
                                { text: 'C#', value: 'csharp' },
                                { text: 'C++', value: 'cpp' },
                                { text: 'bash', value: 'bash'},
                              ],
                            file_picker_types : 'image',
                            relative_urls: false,
                            remove_script_host: false,
                            setup : function (editor) {
                                editor.on('change keyup', async function () {
                                    document.getElementById('preview')!.innerHTML = editor.getContent();
                                    window.MathJax.typeset();
                                    hljs.highlightAll();
                                });
                                editor.addShortcut('meta+e', 'Code to selected text.', () => {
                                    editor.execCommand('FormatBlock', false, 'code');
                                  });
                                editor.ui.registry.addButton('MarkdownDownloadButton', {
                                    icon: 'save',
                                    tooltip: 'Save to Markdown',
                                    onAction: function () {
                                        var content = NodeHtmlMarkdown.translate(editor.getContent());
                                        var blob = new Blob([content], { type: 'text/plain' });
                                        var url = URL.createObjectURL(blob);
                                        var a = document.createElement('a');
                                        a.href = url;
                                        a.download = `${(document.getElementById("Title") as HTMLInputElement).value}.md`; // 파일 이름 설정
                                        document.body.appendChild(a);
                                        a.click();
                                        setTimeout(function() {
                                            document.body.removeChild(a);
                                            window.URL.revokeObjectURL(url); // 메모리 해제
                                        }, 0);
                                    }
                                })
                                editor.ui.registry.addButton('MarkdownUploadButton', {
                                    icon: 'browse',
                                    tooltip: 'Upload Markdown',
                                    onAction: function () {
                                        // Create a file input element
                                        let input = document.createElement('input');
                                        input.type = 'file';
                                        input.accept = '.md';
                                        input.onchange = function (event) {
                                            const target = event.target as HTMLInputElement;
                                            if (!target.files) 
                                                return;
                                            let file = target.files[0];
                                            let reader = new FileReader();
                
                                            reader.onload = function (e) {
                                                // Convert Markdown to HTML
                                                if (!e.target)
                                                    return;
                                                let markdownText = e.target.result as string;
                                                let htmlContent = marked.parse(markdownText) as string;
                                                
                                                // Set HTML content to TinyMCE editor
                                                editor.setContent(htmlContent);
                                            };
                
                                            reader.readAsText(file);
                                        };
                
                                        input.click();
                                    }
                                })
                            },
                            images_upload_handler : image_upload_handler, 
                            file_picker_callback: (cb, value, meta) => {
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', 'image/*');
                            
                                input.addEventListener('change', (e) => {
                                const target = e.target as HTMLInputElement;
                                if (!target.files)
                                    return;
                                const file = target.files[0];
                            
                                const reader = new FileReader();
                                reader.addEventListener('load', () => {
                                    /*
                                    Note: Now we need to register the blob in TinyMCEs image blob
                                    registry. In the next release this part hopefully won't be
                                    necessary, as we are looking to handle it internally.
                                    */
                                    const id = 'blobid' + (new Date()).getTime();
                                    const blobCache =  editorRef.current?.editorUpload.blobCache;
                                    if (!reader.result || !blobCache)
                                        return ;
                                    const base64 = (reader.result as string).split(',')[1];
                                    const blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);
                            
                                    /* call the callback and populate the Title field with the file name */
                                    cb(blobInfo.blobUri(), { title: file.name });
                                });
                                reader.readAsDataURL(file);
                                });
                            
                                input.click();
                            }
                            }}
                        />
                    </div>
                    <div id="preview" className={`basis-1/2 h-[1000px] overflow-auto markdown-body ${previewClassName}`}></div>
                </div>
                <div className='px-5 mb-6'>
                    <div className="flex items-start my-6">
                        <div className="flex items-center h-5">
                            <input id="check" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required />
                        </div>
                        <label htmlFor="check" className="ms-2 text-sm font-medium text-gray-900">I agree with <a href="#!" className="text-blue-600 hover:underlin">publishing this article</a>.</label> 
                    </div>
                    <div className='flex flex-row flex-auto my-3'>
                        <ToggleSwitch Value='Visible'/>
                        <button type="button" onClick={postSubmit} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm ml-5 px-5 py-2.5 me-2 mb-2 focus:outline-none">Submit</button>
                    </div>
                </div>
            </div>
        </form>
        <Footer/>
    </div>
  );
};