import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./pages/Home.tsx";
import Edit from "./pages/Edit.tsx";
import About from "./pages/About.tsx";
import Login from "./pages/Login.tsx";
import Signin from "./pages/Signup.tsx";
import Posts from "./pages/Posts.tsx"
import Projects from "./pages/Projects.tsx";
import Post from "./pages/Post.tsx"
import Category from "./pages/Category.tsx";
import EditPost from "./pages/EditPost.tsx";
import Navbar from "./components/navbar.tsx"
import { useEffect } from "react";

function App() {
  const location = useLocation();

  useEffect(() => {
    const expiryTime = localStorage.getItem('expiryTime');
    if (expiryTime) {
      if (parseInt(expiryTime) - Date.UTC() < 0) {
        localStorage.clear();
      }
    }
  }, []);

  return (
      <div className="App">
        <Navbar/>
        <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/edit" element={<Edit/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/login" element={<Login/>}></Route>
          <Route path="/signup" element={<Signin/>}></Route>
          <Route path="/posts" element={<Posts/>}></Route>
          <Route path="/posts/:id" element={<Post/>}></Route>
          <Route path="/category/:cname" element={<Category/>}></Route>
          <Route path="/edit/:id" element={<EditPost/>}></Route>
          <Route path="/projects" element={<Projects/>}></Route>
        </Routes>
        </AnimatePresence>
      </div>
  );
}

export default App;
