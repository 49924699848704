import React from "react";

interface Category {
    cname : string,
    description : string,
    default_thumb : string,
    default_svg : string
  }

export default function CategoryCard(props : {category : Category}) {
    const hyper = '/category/' + props.category.cname.toLowerCase();
    return (
        <div className="xl:w-1/3 md:w-1/2 p-4">
            <a href={hyper}>
                <div className="border border-gray-200 p-6 rounded-lg">
                <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                    <div className="w-7 h-7 opacity-75">
                    <img alt={props.category.cname} src={props.category.default_svg} ></img>
                    </div>
                </div>
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">{props.category.cname}</h2>
                <p className="leading-relaxed text-base">{props.category.description}</p>
                </div>
            </a>
        </div>
    )
}