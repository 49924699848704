import React, { useEffect, useRef, useState } from 'react'
import { refreshAPI } from './TokenRefresher.tsx';

interface hashtag {
  hashtagId: number,
  hashtagName: string
}

export function HashTagSelector({ tags, onTagsChange, Name }) {
    const [inputValue, setInputValue] = useState<string>('');
    const [hashtags, setHashtags] = useState<string[]>(['a', 'b', 'c', 'c', 'c', 'c', 'c', 'c', 'c', 'c', 'c', 'c', 'c']);
    const [dropdown, setDropdown] = useState(false);
    const allHashtags = useRef<hashtag[]>();

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
          e.preventDefault();
          const trimmedValue = inputValue.trim();
          if (!tags.includes(trimmedValue)) {
            onTagsChange([...tags, trimmedValue]);
          }
          setInputValue('');
          setDropdown(false);
        } else if (e.key === 'Backspace' && inputValue === '' && tags.length > 0) {
            onTagsChange(tags.slice(0, -1));
        }
      };

      const selectDropdownItem = (e) => {
        if (!tags.includes(e.target.innerText)) {
          onTagsChange([...tags, e.target.innerText]);
        }
        setInputValue('');
        setDropdown(false);
      }

      const handleDropdown = (value) => {
        if (allHashtags.current) {
          var tmp_list = ['']
          allHashtags.current.forEach(hashtag => {
            console.log(hashtag.hashtagName, hashtag.hashtagName.includes(value))
            if (value !== '' && hashtag.hashtagName.includes(value))
              tmp_list.push(hashtag.hashtagName);
          console.log(tmp_list)
          if (tmp_list.length - 1 > 0) {
            setHashtags(tmp_list.slice(1));
            setDropdown(true);
          }
          else {
            setDropdown(false);
          }
          });
        }
      }
    
      const handleInputChange = (e) => {
        setInputValue(e.target.value);
        handleDropdown(e.target.value);
      };
    
      const handleTagRemove = (index) => {
        onTagsChange(tags.filter((_, i) => i !== index));
      };

      useEffect(() => {
        refreshAPI.get('https://api.taehyeok.o-r.kr/hashtag/?page=all')
        .then((res) => {
          allHashtags.current = res.data;
        });
      }, [])


    return (
       <div>
            <div className='block'>
                <label htmlFor={Name} className="block mb-2 text-sm font-medium text-gray-700">{Name}</label>
                <div className="flex flex-wrap gap-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5">
                {tags.map((tag, index) => (
                  
                    <div key={index}>
                          <span id="badge-dismiss-indigo" className="inline-flex items-center px-2 py-1 me-2 text-xs font-medium text-indigo-800 bg-indigo-100 rounded dark:bg-indigo-900 dark:text-indigo-300">
                          #{tag}
                            <button onClick={() => handleTagRemove(index)} type="button" className="inline-flex items-center p-1 ms-2 text-sm text-indigo-400 bg-transparent rounded-sm hover:bg-indigo-200 hover:text-indigo-900 dark:hover:bg-indigo-800 dark:hover:text-indigo-300" data-dismiss-target="#badge-dismiss-indigo" aria-label="Remove">
                              <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                              </svg>
                            <span className="sr-only">Remove badge</span>
                            </button>
                          </span>
                    </div>
                    ))}
                    <input type="text" value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} placeholder="press Enter" className="flex-1 bg-gray-50 outline-none border-none"/>
                </div>
                <div className={`${dropdown ? 'block' : 'hidden'} absolute max-h-48 overflow-auto`}>
                  <ul className='min-w-40 bg-gray-100 rounded-md border'>
                    {hashtags.map((hashtag, index) => {
                      return (
                        <li key={index} onClick={selectDropdownItem} className='first:rounded-t-md last:rounded-b-md min-h-8 border-b-2 last:border-b-0 text-gray-600 align-middle py-1 hover:bg-indigo-200'>
                          <div className='flex items-center'>
                            <p className='pl-4 select-none'>
                              {hashtag}
                            </p>
                          </div>
                        </li>
                      )})}
                  </ul>
                </div>
                
            </div>
       </div> 
    );
}