import React, { useEffect, useState } from "react";
import Footer from "../components/footer.tsx";
import { refreshAPI } from "../components/TokenRefresher.tsx";
import CategoryCard from "../components/CategoryCard.tsx";
import Postcard from "../components/postcard.tsx";
import { motion } from "framer-motion";

interface Category {
  cname : string,
  description : string,
  default_thumb : string,
  default_svg : string
}
interface post {
  pid : number,
  cid : {
    cid : number,
    cname : string,
    default_thumb : string
  },
  uid : {
    nickname : string,
    uid : number
  },
  pname : string,
  pthumb : string,
  pthumbname : string,
  slug : string,
  content : string,
  hit : string,
  visible : boolean,
  created : string,
  updated : string
}

export default function Posts() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [mosthits, setMostHits] = useState<post[]>([]);
  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await refreshAPI.get("https://api.taehyeok.o-r.kr/category/")
        setCategories(res.data.results);
      } catch (error) {
        alert("Get Category List Fail");
      }
    }
    const getMostHit = async () => {
      try {
        const res = await refreshAPI.get("https://api.taehyeok.o-r.kr/post/?hit")
        setMostHits(res.data.results);
      } catch (error) {
        alert ("Get MostHit Fail")
      }
    }
    getCategory();
    getMostHit();
  }, [])
  return (
    <motion.div
    className="bg-white"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{
        duration : 0.5,
        ease : 'easeInOut'
    }}
    >
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">대표 주제 리스트</h1>
              <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">CS에서 배우는 다양한 분야의 내용들을 공부하고 기록을 남기는 공간입니다.</p>
            </div>
            <div className="flex flex-wrap -m-4">

            {categories.map((category, idx)=>
              <CategoryCard category={category}></CategoryCard>
            )}          

            </div>
          </div>
        </section>
        <section className="text-gray-600 pt-12 body-font">
          <div className="container px-5 mx-auto">
            <div className="flex flex-wrap w-full mb-10">
              <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Most Popular</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-gray-600 body-font">
          <div className="container px-5 pb-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              {mosthits.map((post, idx)=> 
                <Postcard post={post} className="p-4 md:w-1/3"></Postcard>
              )}
            </div>
          </div>
        </section>
        <Footer></Footer>
    </motion.div>

  );
}
