import axios from 'axios';

export const refreshAPI = axios.create({
    headers: {"Content-Type": "application/json"},
    withCredentials : true
})

refreshAPI.interceptors.response.use(
    async response => {
        return response;
    },
    async error => {
        const originalconfig = error.config;
        const status = error.response.status;
        
        if (status === 401) {
            try {
                await axios.post('https://api.taehyeok.o-r.kr/refresh/')
                return refreshAPI(originalconfig);
            } catch (error) {
                console.log(error);
            }
        }
        else {
            
        }
        console.log("여기로 오면 큰일남...");
        return Promise.reject(error);
    }
);