import Footer from '../components/footer.tsx';
import DisableInputBox from '../components/disableinputbox.tsx';
import Inputbox from '../components/inputbox.tsx';
import { CategorySelector } from '../components/CategorySelector.tsx';
import { HashTagSelector } from '../components/HashTagSelector.tsx';
import { useState } from 'react';
import { refreshAPI } from '../components/TokenRefresher.tsx';
import React from 'react';


interface post {
    content : any,
    username : string,
    created : string,
    category : string,
    pname : string,
    slug : string,
    pthumb : string,
    pthumbname : string,
    visible : boolean,
    hashtags : string[]
}


export default function Edit() {
    const [tags, setTags] = useState<string[]>([]);

    const handleTagsChange = (newTags: string[]) => {
        setTags(newTags);
      };
    const [category, setCategory] = useState('');
    const [isModal, setisModal] = useState(false);
    const [file, setfile] = useState([]);
    const UserName = localStorage.getItem('nickname');
    const curDate = new Date().toLocaleString();

    const closeModal = () => {
        setisModal(false);
    }
    const handleFileChange = (e) => {
        setfile(Array.from(e.target.files));
    }
    const uploadThumbnail = async () => {
        const formData = new FormData();
        file.forEach((file) => {
            formData.append("file", file);
          });
        formData.append("filename", 'thumbnailIMG');
        try {
            const res = await refreshAPI.post('https://api.taehyeok.o-r.kr/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // 필요에 따라 'Content-Type'을 'multipart/form-data'로 설정
                }
            });
            (document.getElementById("ThumbNail URL") as HTMLInputElement).value = res.data['file_url'];
        } catch (err) {
            console.log(err);
            alert('Upload Fail');
        }
        setisModal(false);
    }

    const postSubmit = async () => {
        const content = "여기에 내용을 입력해 주세요";
        const usernameInput = UserName == null ? '' : UserName;
        const createdInput = curDate;
        const categoryInput = category;
        const thumbInput = (document.getElementById("ThumbNail URL") as HTMLInputElement).value;
        const thumbNInput = (document.getElementById("ThumbNail Name") as HTMLInputElement).value;
        const titleInput = (document.getElementById("Title") as HTMLInputElement).value;
        const slugInput = (document.getElementById("Slug") as HTMLInputElement).value;
        const visible = false;
        if (!categoryInput.length) {
            alert('Category 채워주세요')
            document.getElementById("Category")?.focus();
            return false;
        }
        if (!titleInput) {
            alert('Fill Title');
            document.getElementById("Title")?.focus();
            return false;
        }
        var data : post = {
            content : content,
            username : usernameInput,
            created : createdInput,
            category : categoryInput,
            pname : titleInput,
            slug : slugInput,
            pthumb : thumbInput,
            pthumbname : thumbNInput,
            visible : visible,
            hashtags : tags
        }
        try {
            const res = await refreshAPI.post('https://api.taehyeok.o-r.kr/create/',data);
            alert("Make Post Meta Success");
            window.location.href = `https://test.taehyeok.o-r.kr/edit/${res.data.pid}/`;
        } catch {
            alert("post Fail");
        }
        return true;
    };
    
 return (
    <div className='bg-white'>
        <section className="mx-0 md:mx-24 lg:mx-48 text-gray-600 pt-12 body-font">
          <div className="container px-5 mx-auto">
            <div className="flex flex-wrap w-full mb-10">
              <div className="lg:w-1/2 w-full lg:mb-0">
                <h1 className="text-3xl font-medium title-font mb-2 text-gray-900">Meta Data</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
              </div>
            </div>
          </div>
        </section>
        <form>
            <div className='mx-0 md:mx-24 lg:mx-48'>
                <div className="grid px-5 gap-6 mb-6 md:grid-cols-2">
                    <DisableInputBox Name="UserName" Value={UserName}/>
                    <DisableInputBox Name="Created" Value={curDate}/>
                    <CategorySelector Name="Category"  Category={category} setCategory={setCategory}/>
                    <HashTagSelector Name="HashTag" tags={tags} onTagsChange={handleTagsChange}/>
                    <div className='flex'>
                        <div className='grow mr-2'>
                            <Inputbox Name="ThumbNail URL" Value="ThumbNail URL" requied={false}/>
                        </div>
                        <div className='ml-auto mt-7'>
                            <button onClick={() => {setisModal(true);}}type="button" className="mt-0.5 text-white border-2 border-gray-300 bg-gray-100 hover:bg-blue-300 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
                            <svg className="w-5 h-5" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin">
                            <path d="M8 3.414v5.642a1 1 0 1 1-2 0V3.414L4.879 4.536A1 1 0 0 1 3.464 3.12L6.293.293a1 1 0 0 1 1.414 0l2.829 2.828A1 1 0 1 1 9.12 4.536zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2"/>
                            </svg>
                            <span className="sr-only">Icon description</span>
                            </button>
                        </div>
                        {isModal && (
                        <div id="crud-modal" tabIndex={-1} aria-hidden="true" className="flex bg-black bg-opacity-70 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                            <div className="relative p-4 w-full max-w-md max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                            Upload New Picture
                                        </h3>
                                        <button type="button" onClick={closeModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                    <div className="p-4 md:p-5">
                                        <div className="grid gap-4 mb-4 grid-cols-2">
                                            <div className="col-span-2">
                                                <label htmlFor="file-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Thumbnail Picture</label>
                                                <input type="file" name="file-input" id="file-input" className="block w-full border bg-gray-50 border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
                                                    file:bg-blue-700 file:border-0
                                                    file:me-4
                                                    file:py-2 file:px-4
                                                    file:text-white
                                                " onChange={handleFileChange}/>
                                            </div>
                                        </div>
                                        <button type="button" onClick={uploadThumbnail} className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            <svg className="me-1 -ms-1 mt-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 3.414v5.642a1 1 0 1 1-2 0V3.414L4.879 4.536A1 1 0 0 1 3.464 3.12L6.293.293a1 1 0 0 1 1.414 0l2.829 2.828A1 1 0 1 1 9.12 4.536zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2"/>
                                            </svg>
                                            Upload Image
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                    <Inputbox Name="ThumbNail Name" Value="ThumbNail Name" requied={false}/>
                </div>
                <div className='flex flex-col px-5 mb-3 gap-6'>
                    <Inputbox Name="Title" Value="Title" requied={true}/>
                    <Inputbox Name="Slug" Value="Slug" requied={false}/>
                </div>
                <div className='px-5 mb-6'>
                    <button type="button" onClick={postSubmit} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">Submit</button>
                </div>
            </div>
        </form>
        <Footer/>
    </div>
  );
};