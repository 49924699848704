import React from "react";

export default function DisableInputBox(props : {Name : string, Value : string | null}) {
    const value = props.Value == null ? '' : props.Value; 
    return (
        <div>
            <label htmlFor={props.Name} className="block mb-2 text-sm font-medium text-gray-900">{props.Name}</label>
            <input type="text" id={props.Name} aria-label={props.Name} className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 cursor-not-allowed " value={value} readOnly/>
        </div>  
    );
}