import React, { useEffect, useState } from "react";

export default function SkillLevel({level} : {level : number}) {
    const [bgcolor, setbgColor] = useState('');
    const [bordercolor, setborderColor] = useState('');
    const [skill, setSkill] = useState('');
    useEffect(() => {
        switch (level) {
            case 1 :
                setbgColor('bg-red-500');
                setborderColor('bg-red-500');
                setSkill('Beginner');
                break;
            case 2 :
                setbgColor('bg-orange-500');
                setborderColor('bg-orange-500');
                setSkill('Intermediate');
                break;
            case 3 :
                setbgColor('bg-green-500');
                setborderColor('bg-green-500');
                setSkill('Advanced');
                break;
            case 4 :
                setbgColor('bg-blue-500');
                setborderColor('bg-blue-500');
                setSkill('Master');
                break;
            default:
                break;
        }
    }, [level])
    
    return (
    <div className="mt-3">
        <div className="flex relative justify-center">
            <div className="w-1/2 h-2 rounded-full relative overflow-hidden">
                <div className="absolute inset-0 grid grid-cols-4 gap-1 bg-white">
                <div className={`${bgcolor} ${bordercolor} h-full border-r`}></div>
                <div className={`${level >= 2 ? bgcolor : 'bg-gray-300'} ${level >= 2 ? bordercolor : 'border-gray-300'} h-full border-r`}></div>
                <div className={`${level >= 3 ? bgcolor : 'bg-gray-300'} ${level >= 3 ? bordercolor : 'border-gray-300'} h-full border-r`}></div>
                <div className={`${level >= 4 ? bgcolor : 'bg-gray-300'} ${level >= 4 ? bordercolor : 'border-gray-300'} h-full border-r`}></div>
                </div>
            </div>
        </div>
        <p className="text-gray-600 font-semibold text-sm">{skill}</p>
    </div>
    );
}