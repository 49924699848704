import React, { useCallback, useEffect, useRef, useState } from "react";
import Footer from "../components/footer.tsx";
import { useParams } from "react-router-dom";
import { refreshAPI } from "../components/TokenRefresher.tsx";
import Postcard from "../components/postcard.tsx";
import { motion } from "framer-motion";

interface post {
    pid : number,
    cid : {
      cid : number,
      cname : string,
      default_thumb : string
      description : string,
      default_svg : string
    },
    uid : {
      nickname : string,
      uid : number
    },
    pname : string,
    pthumb : string,
    pthumbname : string,
    slug : string,
    content : string,
    hit : string,
    visible : boolean,
    created : string,
    updated : string
  }

export default function Category() {
    const params = useParams();
    const [recentPosts, setrecentPosts] = useState<post[]>([]);
    const [loading, setLoading] = useState(false);
    const Nextpage = useRef(`https://api.taehyeok.o-r.kr/post/?page=1&cname=${params.cname}`);
    const hasMore = useRef(false);

    const loadMoreItems = useCallback(async () => {
        if (loading) return;
        setLoading(true);
        try {
            const res = await refreshAPI.get(Nextpage.current);
            const newPosts = res.data.results;
            hasMore.current = res.data.next === null ? false : true;
            setrecentPosts(prevPosts => [...prevPosts, ...newPosts]);
            if (res.data.next !== null)
                Nextpage.current = res.data.next.replace('http://', 'https://');
            console.log(Nextpage.current);
        } catch {
            alert("post Fail");
        } finally {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        const loadItem = async () => {
            try {
                const res = await refreshAPI.get(Nextpage.current);
                const newPosts = res.data.results;
                hasMore.current = res.data.next === null ? false : true;
                setrecentPosts(prevPosts => [...prevPosts, ...newPosts]);
                if (res.data.next !== null)
                    Nextpage.current = res.data.next.replace('http://', 'https://');
                console.log(Nextpage.current);
            } catch {
                alert("post Fail");
            }
        }
        loadItem();
    }, []);

    const observer = useRef<IntersectionObserver | null>(null);
    const lastItemElementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
        if (entries[0].isIntersecting && hasMore.current) {
            console.log('Last item is in view'); // 디버깅: 마지막 항목이 뷰에 있음
            loadMoreItems();
        }
    };

    observer.current = new IntersectionObserver(observerCallback, {
        rootMargin: '100px',
    });

    const lastItem = lastItemElementRef.current;

    if (lastItem) {
        observer.current.observe(lastItem);
    }

    return () => {
        if (lastItem && observer.current) {
        observer.current.unobserve(lastItem);
        }
    };
    }, [loadMoreItems]);
    
    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
            duration : 0.5,
            ease : 'easeInOut'
        }}
      >
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap w-full mb-20">
                    <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                        {recentPosts.length > 0 && <img alt={recentPosts[0].cid.cname} src={recentPosts[0].cid.default_svg} width={30}></img>}
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">{recentPosts.length > 0 && recentPosts[0].cid.cname}</h1>
                        <div className="h-1 w-20 bg-indigo-500 rounded"></div>
                    </div>
                    <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">{recentPosts.length > 0 && recentPosts[0].cid.description}</p>
                    </div>
                    <div className="flex flex-wrap -m-4">
                        {recentPosts.map((post, index)=>
                        
                            <div className="p-4 md:w-1/3" key={post.pid} ref={index === recentPosts.length - 1 ? lastItemElementRef : null}>
                                <Postcard post={post} className="">
                                    
                                </Postcard>
                            </div>
                        )}
                        {loading && <p>Loading...</p>}
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </motion.div>
    )
}