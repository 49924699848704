
import Postcard from "../components/postcard.tsx";
import Footer from "../components/footer.tsx";
import React, { useEffect, useState } from "react";
import { refreshAPI } from "../components/TokenRefresher.tsx";
import { motion } from "framer-motion";

interface posthashtag {
  hashtagId: number,
  hashtagName: string
}

interface post {
  pid : number,
  cid : {
    cid : number,
    cname : string,
    default_thumb : string
  },
  uid : {
    nickname : string,
    uid : number
  },
  hashtags : posthashtag[],
  pname : string,
  pthumb : string,
  pthumbname : string,
  slug : string,
  content : string,
  hit : string,
  visible : boolean,
  created : string,
  updated : string
}

export default function Home() {
  const [recentPosts, setrecentPosts] = useState<post[]>([]);

  useEffect(() => {
    const getPost = async () => {
      try {
        const res = await refreshAPI.get("https://api.taehyeok.o-r.kr/post/?recent=true")
        setrecentPosts(res.data.results);
      } catch (error) {
        alert("Get Recent Post Fail");
      }
    }
    getPost();
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
          duration : 0.5,
          ease : 'easeInOut'
      }}
    >
      <div className="bg-white">
        <section className={`text-gray-600 body-font`}>
          <div className="container px-5 py-24 mx-auto">
            <div className="text-center mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">Welcome to Dev.K Blog</h1>
              <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">Dev.K 블로그에 방문을 환영합니다. 다양한 CS관련 공부한 내용을 기록하는 공간입니다.</p>
              <div className="flex mt-6 justify-center">
                <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
              </div>
            </div>
            <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
              <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-semibold mb-3">About</h2>
                  <p className="leading-relaxed text-base">저자 Dev.K에 대한 정보를 소개합니다. 저자가 어떤 내용에 관심있는게 공부하고 있는지 알아볼 수 있습니다.</p>
                  <a href="about" className="mt-3 text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                  <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" className="w-10 h-10" viewBox="0 0 40 40">
                    <path d="M12.966 17.262c-.483 0-.878.395-.878.878s.395.877.878.877h13.518c.483 0 .878-.394.878-.877s-.395-.878-.878-.878z"/>
                    <path d="M9.411 35a.763.763 0 0 1-.759-.746V5.758c0-.418.34-.758.759-.758h14.704c.2 0 .395.084.547.236l6.449 6.461c.15.13.236.324.236.534v22.022a.764.764 0 0 1-.759.746zm.759-1.504h19.672V12.989h-5.727a.76.76 0 0 1-.758-.757V6.504H10.17zm14.705-22.011h3.9l-3.9-3.915z"/><path d="M12.966 21.164c-.483 0-.878.395-.878.878s.395.878.878.878h13.518c.483 0 .878-.395.878-.878s-.395-.878-.878-.878zm0 3.901a.88.88 0 0 0-.878.878c0 .484.395.877.878.877h13.518a.88.88 0 0 0 .878-.877.88.88 0 0 0-.878-.878z"/>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-semibold mb-3">Posts</h2>
                  <p className="leading-relaxed text-base">다양한 CS관련 내용을 포스트합니다. Algorithm, BLOG 제작, Cloud 등을 다루고 있습니다. </p>
                  <a href="posts" className="mt-3 text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
                  <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 512 512">
                    <path d="m256 34.347 192 110.851v221.703L256 477.752 64 366.901V145.197zM106.666 192.001v150.266l128 73.9V265.902zm298.667.001-128 73.9v150.265l128-73.9zM256 83.614l-125.867 72.67L256 228.952l125.867-72.67z" fill-rule="evenodd"/>
                  </svg>
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-semibold mb-3">Projects</h2>
                  <p className="leading-relaxed text-base">현재까지 진행한 작고 큰 프로젝트를 전시하는 공간입니다. 몇몇 프로젝트는 웹상에서 체험해볼 수 있습니다. 현재는 준비중입니다...</p>
                  <a href="projects" className="mt-3 text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={` text-gray-600 pt-12 body-font`}>
          <div className="container px-5 mx-auto">
            <div className="flex flex-wrap w-full mb-10">
              <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Recent Posts</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
              </div>
            </div>
          </div>
        </section>
        <section className={`text-gray-600 body-font`}>
          <div className="container px-5 pb-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              {recentPosts.map((post, idx)=> 
                <Postcard post={post} className="p-4 md:w-1/3"></Postcard>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer/>
    </motion.div>
  );
}
