import React from "react";

export default function Develop() {
    return (
        <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
                width={300}
                height={300}
                className=""
            >
                <g>
                <polygon
                    style={{
                    fill: "#D7DEED",
                    }}
                    points="503.83,32.681 503.83,98.043 449.362,119.83 471.149,32.681 "
                    data-original="#367596"
                    className=""
                    data-old_color="#367596"
                />
                <polygon
                    style={{
                    fill: "#E4EAF6",
                    }}
                    points="471.149,32.681 471.149,119.83 305.021,119.83 326.809,32.681 "
                    data-original="#5195AF"
                    className=""
                    data-old_color="#5195AF"
                />
                <polygon
                    style={{
                    fill: "#E4EAF6",
                    }}
                    points="8.17,32.681 8.17,98.043 40.851,119.83 326.809,119.83 326.809,32.681 "
                    data-original="#27467A"
                    className=""
                    data-old_color="#27467A"
                />
                <polygon
                    style={{
                    fill: "#D7DEED",
                    }}
                    points="503.83,98.043 503.83,479.319 471.149,479.319 427.574,288.681 471.149,98.043 "
                    data-original="#A0F6FA"
                    className=""
                    data-old_color="#A0F6FA"
                />
                <rect
                    x={8.17}
                    y={98.043}
                    style={{
                    fill: "#E4EAF6",
                    }}
                    width={462.979}
                    height={381.277}
                    data-original="#D0FBFD"
                    className=""
                    data-old_color="#D0FBFD"
                />
                <polygon
                    style={{
                    fill: "#FF9965",
                    }}
                    points="149.488,164.84 272.381,272.327 379.921,395.274 347.268,427.927 116.673,197.331   149.433,164.787 "
                    data-original="#FF0F47"
                    className=""
                    data-old_color="#ff9965"
                />
                <polygon
                    style={{
                    fill: "#F9894E",
                    }}
                    points="164.893,149.434 395.327,379.868 379.921,395.274 149.488,164.84 149.433,164.787 "
                    data-original="#FF6176"
                    className=""
                    data-old_color="#FC8F56"
                />
                <polygon
                    style={{
                    fill: "#111449",
                    }}
                    points="131.1,395.012 148.086,396.592 149.664,413.576 117.274,427.403 "
                    data-original="#111449"
                />
                <polygon
                    style={{
                    fill: "#FFFFFF",
                    }}
                    points="191.491,353.185 188.903,396.815 149.664,413.576 131.1,395.012 147.862,355.774 "
                    data-original="#FFFFFF"
                />
                <polygon
                    style={{
                    fill: "#FFCD60",
                    }}
                    points="324.406,179.229 147.862,355.774 173.497,381.409 373.15,197.162 "
                    data-original="#FFCD60"
                    className=""
                />
                <polygon
                    style={{
                    fill: "#F3A933",
                    }}
                    points="361.611,193.295 365.447,220.271 188.903,396.815 173.497,381.409 "
                    data-original="#F3A933"
                    className=""
                />
                <path
                    style={{
                    fill: "#F98C54",
                    }}
                    d="M350.041,204.865l15.406,15.406l20.783-20.783c11.315-11.315,11.316-29.725,0-41.042L350.041,204.865  z"
                    data-original="#C20035"
                    className="active-path"
                    data-old_color="#FB905A"
                />
                <path
                    style={{
                    fill: "#FF9965",
                    }}
                    d="M345.188,158.448c11.315-11.315,29.725-11.315,41.042,0c2.819,2.819-4.091,14.32-15.406,25.635  l-20.783,20.783l-25.636-25.636L345.188,158.448z"
                    data-original="#FF0F47"
                    className=""
                    data-old_color="#ff9965"
                />
                <path
                    d="M503.83,24.511H8.17c-4.512,0-8.17,3.658-8.17,8.17v446.638c0,4.512,3.658,8.17,8.17,8.17h495.66  c4.513,0,8.17-3.658,8.17-8.17V392.17c0-4.512-3.657-8.17-8.17-8.17s-8.17,3.658-8.17,8.17v78.979H16.34V106.213H495.66v253.277  c0,4.512,3.657,8.17,8.17,8.17s8.17-3.658,8.17-8.17V32.681C512,28.169,508.343,24.511,503.83,24.511z M16.34,40.851h302.298v49.021  H16.34V40.851z M334.979,89.872V40.851H495.66v49.021H334.979z"
                    data-original="#000000"
                    className=""
                    style={{
                    fill: "#000000",
                    }}
                />
                <path
                    d="M415.591,56.647c-4.805,0-8.715,3.91-8.715,8.715s3.91,8.715,8.715,8.715c4.805,0,8.715-3.91,8.715-8.715  S420.397,56.647,415.591,56.647z"
                    data-original="#000000"
                    className=""
                    style={{
                    fill: "#000000",
                    }}
                />
                <path
                    d="M370.928,56.647c-4.805,0-8.715,3.91-8.715,8.715s3.91,8.715,8.715,8.715s8.715-3.91,8.715-8.715  S375.733,56.647,370.928,56.647z"
                    data-original="#000000"
                    className=""
                    style={{
                    fill: "#000000",
                    }}
                />
                <path
                    d="M460.255,56.647c-4.805,0-8.715,3.91-8.715,8.715s3.91,8.715,8.715,8.715c4.805,0,8.715-3.91,8.715-8.715  S465.06,56.647,460.255,56.647z"
                    data-original="#000000"
                    className=""
                    style={{
                    fill: "#000000",
                    }}
                />
                <path
                    d="M164.876,160.968l65.789,65.789c3.191,3.191,8.364,3.191,11.554,0c3.191-3.191,3.191-8.364,0-11.555l-71.546-71.546  c-3.183-3.183-8.341-3.192-11.534-0.02l-48.224,47.898c-1.54,1.531-2.409,3.611-2.413,5.783c-0.003,2.172,0.857,4.255,2.393,5.791  l31.601,31.601c0.002,0.002,0.003,0.003,0.004,0.005c0.002,0.002,0.004,0.003,0.005,0.005l40.098,40.098  c1.595,1.595,3.686,2.393,5.777,2.393c2.092,0,4.182-0.797,5.777-2.393c3.191-3.191,3.191-8.364,0-11.554l-2.72-2.72l18.697-18.697  c3.191-3.191,3.191-8.364,0-11.554c-3.191-3.191-8.364-3.191-11.555,0l-18.697,18.697l-20.052-20.052l18.697-18.697  c3.191-3.191,3.191-8.364,0-11.554c-3.191-3.191-8.364-3.191-11.554,0l-18.697,18.697l-20.032-20.032L164.876,160.968z"
                    data-original="#000000"
                    className=""
                    style={{
                    fill: "#000000",
                    }}
                />
                <path
                    d="M278.331,370.546C278.332,370.547,278.332,370.547,278.331,370.546l0.001,0.001l63.158,63.158  c1.533,1.533,3.61,2.393,5.778,2.393c2.167,0,4.244-0.861,5.778-2.393l48.06-48.062c3.191-3.191,3.191-8.364,0-11.554  l-71.628-71.628c-3.192-3.191-8.364-3.191-11.555,0c-3.191,3.191-3.191,8.364,0,11.554l65.851,65.851l-36.507,36.507l-20.025-20.025  l18.697-18.698c3.191-3.191,3.191-8.364,0-11.555c-3.192-3.191-8.364-3.191-11.555,0l-18.697,18.697l-20.025-20.025l18.698-18.697  c3.191-3.191,3.191-8.364,0-11.555c-3.191-3.191-8.363-3.191-11.555,0l-18.697,18.697l-2.693-2.693  c-3.192-3.191-8.364-3.191-11.555,0c-3.191,3.191-3.191,8.364,0,11.555L278.331,370.546z"
                    data-original="#000000"
                    className=""
                    style={{
                    fill: "#000000",
                    }}
                />
                <path
                    d="M117.281,435.573c1.082,0,2.172-0.215,3.206-0.657l71.486-30.527c0.011-0.004,0.022-0.01,0.032-0.014l0.112-0.048  c0.07-0.029,0.136-0.068,0.205-0.099c0.155-0.071,0.309-0.142,0.46-0.223c0.089-0.048,0.175-0.103,0.263-0.156  c0.137-0.081,0.276-0.159,0.41-0.249c0.113-0.076,0.22-0.161,0.33-0.243c0.101-0.076,0.206-0.147,0.304-0.228  c0.207-0.17,0.407-0.35,0.597-0.54l176.541-176.54c0,0,0,0,0.001-0.001l0,0l20.786-20.786c7.014-7.016,10.878-16.356,10.878-26.298  c0.001-9.943-3.863-19.283-10.878-26.298c-7.016-7.015-16.356-10.879-26.299-10.879c-9.943,0-19.282,3.864-26.298,10.879  l-20.78,20.781c-0.002,0.002-0.004,0.003-0.007,0.005c-0.002,0.002-0.003,0.004-0.005,0.007L142.09,349.996  c-0.191,0.19-0.369,0.39-0.539,0.597c-0.083,0.101-0.156,0.208-0.233,0.312c-0.08,0.107-0.162,0.211-0.236,0.321  c-0.093,0.137-0.173,0.279-0.256,0.42c-0.049,0.084-0.102,0.166-0.148,0.252c-0.083,0.154-0.155,0.312-0.228,0.47  c-0.031,0.066-0.066,0.13-0.096,0.197l-0.047,0.109c-0.005,0.013-0.011,0.025-0.016,0.037l-30.526,71.484  c-1.311,3.069-0.623,6.627,1.736,8.986C113.065,434.744,115.154,435.574,117.281,435.573z M324.408,190.786l29.487,29.487  L188.908,385.259l-29.487-29.487L324.408,190.786z M350.972,164.223c3.928-3.93,9.165-6.094,14.743-6.094  c5.578,0,10.814,2.163,14.742,6.094c0,0,0,0,0.001,0c3.929,3.928,6.093,9.165,6.093,14.742c0,5.579-2.163,10.814-6.094,14.745  l-15.008,15.008l-29.487-29.487L350.972,164.223z M150.635,370.094l23.952,23.952l-41.803,17.851L150.635,370.094z"
                    data-original="#000000"
                    className=""
                    style={{
                    fill: "#000000",
                    }}
                />
                </g>
            </svg>
                <div className="text-center lg:w-2/3 w-full">
                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">준비중입니다...</h1>
                <div className="flex justify-center">
                    <a href="/" className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-12">Home</a>
                </div>
                </div>
            </div>
        </section>
    );
}