import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {refreshAPI} from'./TokenRefresher.tsx';
axios.defaults.withCredentials = true;

export default function Navbar() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [dropdown, setdropdown] = useState(false);
  const [nickname, setnickname] = useState<string | null>('');
  const [email, setemail] = useState<string | null>('');

  async function logout() {
    try {
      await refreshAPI.post('https://api.taehyeok.o-r.kr/logout/');
      localStorage.clear();
      setLoggedIn(false);
      window.location.reload();
    }
    catch (error) {
      console.log(error);
    }
  }
    useEffect(() => {
      const login = localStorage.getItem('isLogin') === 'true' ? true : false;
      if (login) {
          setLoggedIn(true);
          setemail(localStorage.getItem('email'));
          setnickname(localStorage.getItem('nickname'));
      }
    }, [])

    return (
    <header className="bg-white text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a href='#!' className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
              <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
            </svg>
            <span className="ml-3 text-xl">Dev.K</span>
          </a>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
            <a href="/" className="mr-5 hover:scale-110 transition-transform duration-200 ease-in-out">Home</a>
            <a href="/about" className="mr-5 hover:scale-110 transition-transform duration-200 ease-in-out">About</a>
            <a href="/posts" className="mr-5 hover:scale-110 transition-transform duration-200 ease-in-out">Posts</a>
            <a href="/projects" className="mr-5 hover:scale-110 transition-transform duration-200 ease-in-out">Projects</a>
          </nav>
          <div className='flex gap-5'>
            <div className="hidden lg:flex">
              <div className="relative md:block">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                  </svg>
                  <span className="sr-only">Search icon</span>
                </div>
                <input type="text" id="search-navbar" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..."/>
              </div>
            </div> 
          {isLoggedIn ? (
            <div className='flex gap-5'>
              <a href="/edit" className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">New Post
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
              <div className='flex-col flex items-center relative'>
                <button onClick={() => {setdropdown((e) => !e); console.log(dropdown)}} type="button" className="hidden py-0.5 md:flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                  <span className="sr-only">Open user menu</span>
                  <svg className="md:w-6 md:h-6 lg:w-8 lg:h-8 mx-1 mt-0.5" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                    <circle cx="8" cy="6" r="3.25"/>
                    <path d="m2.75 14.25c0-2.5 2-5 5.25-5s5.25 2.5 5.25 5"/>
                  </svg>
                </button>
                {dropdown && (
                <div className="hidden absolute md:block top-10 mt-1 z-50 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow" id="user-dropdown">
                  <div className="px-4 py-3">
                    <span className="flex justify-center text-sm font-semibold text-gray-900 dark:text-white">{nickname}</span>
                    <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">{email}</span>
                  </div>
                  <ul className="py-2" aria-labelledby="user-menu-button">
                    <li>
                      <a href="#!" onClick={logout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</a>
                    </li>
                  </ul>
                </div>
                )}
              </div>
            </div>
          ) : (
            <a href="/login" className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Log in
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          )}
          </div>
        </div>
      </header>
    );
  }