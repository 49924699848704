import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { refreshAPI } from "../components/TokenRefresher.tsx";
import React from "react";
import Footer from "../components/footer.tsx";
import DOMPurify from 'dompurify'
import hljs from "highlight.js";
import { motion } from "framer-motion";
import Postcard from "../components/postcard.tsx";

interface posthashtag {
    hashtagId: number,
    hashtagName: string
}

interface comment {
    commentId : number,
    name : string,
    content : string,
    created : string,
    updated : string,
    uid : number,
    pid : number
}

interface tocItem {
    id : number,
    text: string | null,
    level: number,
    indent : string,
    svg : boolean
}

interface post {
    pid : number,
    cid : {
      cid : number,
      cname : string,
      default_thumb : string
    },
    uid : {
      nickname : string,
      uid : number
    },
    hashtags : posthashtag[],
    comments : comment[],
    pname : string,
    pthumb : string,
    pthumbname : string,
    slug : string,
    content : string,
    hit : string,
    visible : boolean,
    created : string,
    updated : string
  }

export default function Post() {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [valid, setVaild] = useState<boolean>(false);
    const [pname, setpname] = useState<string>('');
    const [slug, setslug] = useState<string>('');
    const [created, setcreated] = useState<string>();
    const [updated, setupdated] = useState<string>('');
    const [content, setcontent] = useState<string>('');
    const [nickname, setnickname] = useState<string>('');
    const [category, setcategory] = useState<string>('');
    const [hit, sethit] = useState<number>(0);
    const [thumb,setthumb] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [tocItems, setTocItems] = useState<tocItem[]>([]);
    const [comments, setComments] = useState<comment[]>([]);
    const [myName, setMyName] = useState('');
    const [prevPost, setPrevPost] = useState<post | null>(null);
    const [nextPost, setNextPost] = useState<post | null>(null);

    const commentText= useRef("");
    const commentName = useRef("");
    
    const params = useParams();

    const onChangeCommentNameInput = (e) => {
        commentName.current = e.target.value;
    }

    const onChangeCommentInput = (e) => {
        commentText.current = e.target.value;
    }

    const onClickEraseComment = (e) => {
        refreshAPI.delete(`https://api.taehyeok.o-r.kr/comment/${e.target.id}/`)
        .then((res) => {
            alert('Comment Deleted');
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        });
    }

    const submitComment = (e) => {
        e.preventDefault();
        var data = {
            'name' : myName === '' ? commentName.current : myName,
            'content' : commentText.current,
            'pid' : params.id
        };
        refreshAPI.post('https://api.taehyeok.o-r.kr/comment/', data)
        .then((res) => {
            alert('Comment Uploaded');
            window.location.reload();
        }).catch((err) => {
            alert('이름을 변경하세요')
        });
    }

    const editPost = () => {
        window.location.href = '/edit/' + params.id + '/';
    }

    const delPost = async () => {
        try {
            await refreshAPI.delete('https://api.taehyeok.o-r.kr/post/' + params.id + '/');
            alert('삭제 성공~~');
            window.location.href = '/';
        } catch (err) {
            alert('삭제 실패');
        }
    }

    useEffect(() => {
        const login = localStorage.getItem('isLogin') === 'true' ? true : false;
        if (login) {
            setLoggedIn(true);
            const name = localStorage.getItem('nickname');
            if (name) {
                setMyName(name);
            }
        }
      }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = 'https://api.taehyeok.o-r.kr/post/' + params.id + '/';
                const res = await refreshAPI.get(url);
                var tmp_tags = ['']
                res.data.hashtags.forEach((tag : posthashtag)=> {
                    tmp_tags = [...tmp_tags, tag.hashtagName]
                })
                setComments(res.data['comments']);
                setTags(tmp_tags.slice(1))
                setpname(res.data['pname']);
                setslug(res.data['slug']);
                setcontent(res.data['content']);
                setcreated(res.data['created'].substr(0,10));
                setnickname(res.data['uid']['nickname']);
                setcategory(res.data['cid']['cname']);
                setupdated(res.data['updated']);
                sethit(Number.parseInt(res.data['hit']));
                setVaild(true);
                if (res.data['pthumb'] === '')
                    setthumb(res.data['cid']['default_thumb']);
                else
                    setthumb(res.data['pthumb']);
                if (res.data['prev_post']) {
                    refreshAPI.get('https://api.taehyeok.o-r.kr/post/' + res.data['prev_post'] + '/')
                    .then((res) => {
                        setPrevPost(res.data);
                    });
                }
                if (res.data['next_post']) {
                    refreshAPI.get('https://api.taehyeok.o-r.kr/post/' + res.data['next_post'] + '/')
                    .then((res) => {
                        setNextPost(res.data);
                    });
                }
            } catch {
                alert("post Fail");
            }
        }
        fetchData();
    },[params.id])

    useEffect(() => {
        const headings = document.querySelectorAll('h1, h2, h3');
        const tocItems = new Array<tocItem>();
        if (headings.length) {
            headings.forEach((heading, idx) => {
                heading.setAttribute('id', idx.toString());
                const level = parseInt(heading.tagName.slice(1), 10);
                const indent = level === 1 ? 'border-l-4 rounded-sm font-semibold' : 'pl-3';
                const svg = level !== 1;
                const listItem = {
                    id : idx,
                    text: heading.textContent,
                    level,
                    indent,
                    svg
                };
                tocItems.push(listItem);
            })
            setTocItems(tocItems);
        }
        try {
            if (window.MathJax) {
                window.MathJax.typeset();
            }
            hljs.highlightAll();
        } catch (err) {
            console.log(err);
        }
        const elements = document.querySelectorAll('[className^="language-"]');
        console.log(elements);
        // 각 요소에 특정 HTML 코드를 추가합니다.
        elements.forEach(element => {
            // 예를 들어, 아래와 같은 HTML 코드를 추가합니다.
            const newHtml = '<div className="flex -mb-10"> <p className="ml-auto text-gray-500">' + element.className.slice(9) + '<p> </div>';

            // 요소의 마지막에 새로운 HTML 코드를 추가합니다.
            element.insertAdjacentHTML('afterbegin', newHtml);
        });
    }, [content])

    return (
    <motion.div
    className="bg-white"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{
        duration : 0.5,
        delay : 0.2,
        ease : 'easeInOut'
    }}
    >
        {valid && (
            <div>
                <div className="flex justify-center mx-auto ">
                    <div className="w-5/6 md:w-3/5">
                        <div className="flex justify-center">
                            <img alt="thumbnail" className="mb-2 md:mb-10 content-center" src={thumb}></img>
                        </div>
                        <div className="flex flex-col md:pr-10 md:mb-5 mb-6 pr-0 w-full md:w-auto md:text-left text-center p-3">
                            <div className="hidden md:flex mb-2">
                            {tags.map((tag) => 
                                <div>
                                    <span id="badge-dismiss-indigo" className="inline-flex items-center px-2 py-1 me-2 text-xs font-medium text-indigo-800 bg-indigo-100 rounded dark:bg-indigo-900 dark:text-indigo-300">
                                    #{tag}
                                    </span>
                                </div>
                                )}
                            </div>
                            <div className="flex flex-col sm:flex-row gap-x-3 my-5 sm:my-0">
                                <p className="text-sm text-indigo-500 tracking-widest font-medium title-font mb-1">{category}</p>
                                <span className="text-gray-400 inline-flex items-center lg:ml-auto md:ml-0 sm:ml-auto leading-none text-sm pr-3 py-1 border-l-2 pl-2 sm:pl-0 sm:border-r-2 sm:border-l-0 border-gray-200">  
                                <svg className="w-4 h-4 mr-1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                                    <circle cx="8" cy="6" r="3.25"/>
                                    <path d="m2.75 14.25c0-2.5 2-5 5.25-5s5.25 2.5 5.25 5"/>
                                </svg>{nickname}
                                </span>
                                <span className="text-gray-400 inline-flex items-center leading-none text-sm pr-3 py-1 border-l-2 pl-2 sm:pl-0 sm:border-r-2 sm:border-l-0 border-gray-200">
                                    <svg className="w-4 h-4 mr-1" fill="#9CA3AF" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19,4H17V3a1,1,0,0,0-2,0V4H9V3A1,1,0,0,0,7,3V4H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm1,15a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12H20Zm0-9H4V7A1,1,0,0,1,5,6H7V7A1,1,0,0,0,9,7V6h6V7a1,1,0,0,0,2,0V6h2a1,1,0,0,1,1,1Z"/>
                                    </svg> {created}
                                </span>
                                <span className="text-gray-400 inline-flex items-center leading-none text-sm pr-3 py-1 border-l-2 pl-2 sm:pl-0 sm:border-r-2 sm:border-l-0 border-gray-200">  
                                    <svg className="w-4 h-4 mr-1" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>{hit}
                                </span>
                                <span className="text-gray-400 inline-flex items-center leading-none text-sm border-l-2 pl-2 sm:pl-0 sm:border-l-0">
                                <svg className="w-4 h-4 mr-1" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                </svg>0
                                </span>
                            </div>
                            <p id='title' className="md:text-3xl text-2xl font-semibold title-font text-gray-900">{pname}</p>
                            <div className="hidden md:block my-3 h-1 w-20 bg-indigo-500 rounded"></div>
                            <p className="leading-relaxed text-sm text-gray-500">{slug}</p>
                        </div>
                        <div className="border rounded-lg bg-gray-100 pl-6 py-5 pr-12 mb-10">
                                <div className="text-2xl mb-5 font-bold">Index</div>
                                <div className="mb-5">
                                    <a href="#title" className="text-slate-900 font-bold text-xl leading-6 hover:text-sky-500">{pname}</a>
                                </div>
                                <ul id="toc" className="text-slate-700 mt-1 text-sm leading-6 ">
                                    {tocItems.map((item, idx) => 
                                        <li key={item.id} className={`${item.indent} border-stone-400 my-1 hover:border-sky-500`}>
                                            <a href={`#${item.id}`} className="flex py-1 text-gray-500 ml-3 hover:text-sky-500">
                                                {item.svg && (<svg width="3" height="24" viewBox="0 -9 3 24"
                                                    className="mr-2 text-slate-400 overflow-visible group-hover:text-slate-600 dark:text-slate-600 dark:group-hover:text-slate-500">
                                                    <path d="M0 0L3 3L0 6" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round">
                                                    </path>
                                                </svg>)}
                                                {item.text}</a>
                                        </li>
                                    )}
                                </ul>
                        </div>
                            <div className="markdown-body" dangerouslySetInnerHTML={{__html : DOMPurify.sanitize(content)}}></div>
                        {isLoggedIn && (
                            <div className="flex gap-x-2">
                                <button onClick={editPost} type="button" className="ml-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">수정</button>
                                <button onClick={delPost} type="button" className="mr-5 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">삭제</button>
                            </div>
                        )}
                        <div>
                            <div className="flex gap-2 text-lg lg:text-2xl font-bold text-gray-900 mb-3">
                                <h1 >댓글</h1>
                                ({comments.length})
                            </div>
                                <form className="mb-6">
                                    <div className={`${myName === '' ? 'block' : 'hidden'}`}>
                                        <label htmlFor="comment-name" className="sr-only">Your Name</label>
                                        <input onChange={onChangeCommentNameInput} id="comment-name" type="text" className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 max-w-sm font-semibold text-base" placeholder="Your Name">
                                        </input>
                                    </div>
                                    <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200">
                                        <label htmlFor="comment" className="sr-only">Your comment</label>
                                        <textarea onChange={onChangeCommentInput} id="comment" rows={6}
                                            className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                                            placeholder="Write a comment..." required></textarea>
                                    </div>
                                    <button type="submit" onClick={submitComment}
                                        className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-indigo-700 rounded-lg focus:ring-4 focus:ring-indigo-200hover:bg-indigo-800">
                                        Post comment
                                    </button>
                                </form>
                            {comments.map((comment : comment) => {
                                return (
                                    <div className="my-0.5">
                                        <div className="p-3 text-base bg-white rounded-lg dark:bg-gray-900">
                                            <div className="flex justify-between items-center mb-2">
                                                <div className="flex items-center">
                                                    <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold"><img
                                                            className="mr-2 w-6 h-6 rounded-full"
                                                            src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"
                                                            alt="Michael Gough"/>{comment.name}</p>
                                                    <p className="text-sm text-gray-600 dark:text-gray-400">{comment.created}</p>
                                                </div>
                                                <button id={comment.commentId.toString()} onClick={onClickEraseComment}
                                                    className={`${myName === comment.name ? 'block' : 'hidden'} inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                                                    type="button`}>
                                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3h3v1h-1v9l-1 1H4l-1-1V4H2V3h3V2a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1zM9 2H6v1h3zM4 13h7V4H4zm2-8H5v7h1zm1 0h1v7H7zm2 0h1v7H9z"/>
                                                    </svg>
                                                    <span className="sr-only">Comment Erase</span>
                                                </button>
                                            </div>
                                            <p className="break-words text-gray-500 dark:text-gray-400">{comment.content}</p>
                                            <hr className="mt-1"></hr>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="flex flex-wrap mt-5">
                            {prevPost && (
                                <div className={`w-full md:w-1/2 mb-10`}>
                                    <div className="flex flex-col">
                                        <div className="flex gap-x-3 items-center text-center justify-start ml-3 mb-3">
                                            <svg className="w-10 h-10" viewBox="0 0 30 30" data-name="21 - Left" xmlns="http://www.w3.org/2000/svg">
                                                <path data-name="Path 207" d="M15 0a15 15 0 1 0 15 15A15.007 15.007 0 0 0 15 0m0 2A13 13 0 1 1 2 15 13.006 13.006 0 0 1 15 2" fill-rule="evenodd"/>
                                                <path data-name="Path 208" d="M18.707 20.293 13.414 15l5.293-5.293a1 1 0 0 0-1.414-1.414l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 1 0 1.414-1.414" fill-rule="evenodd"/>
                                            </svg>
                                            <p className="text-2xl font-bold">이전 글</p>
                                        </div>
                                        <Link to={prevPost.pid.toString()} className="mr-auto max-w-96">
                                            <Postcard post={prevPost} className=""></Postcard>
                                        </Link>
                                    </div>
                                </div>)}
                            {nextPost && (
                                <div className={`w-full md:w-1/2 ml-auto mb-10`}>
                                    <div className="flex flex-col">
                                        <div className="flex gap-x-3 items-center text-center justify-end mr-3 mb-3">
                                            <p className="text-2xl font-bold">다음 글</p>
                                            <svg className="w-10 h-10" viewBox="0 0 30 30" data-name="23 - Right" xmlns="http://www.w3.org/2000/svg">
                                                <path data-name="Path 211" d="M15 0a15 15 0 1 0 15 15A15.007 15.007 0 0 0 15 0m0 2A13 13 0 1 1 2 15 13.006 13.006 0 0 1 15 2" fill-rule="evenodd"/>
                                                <path data-name="Path 212" d="M11.293 9.707 16.586 15l-5.293 5.293a1 1 0 0 0 1.414 1.414l6-6a1 1 0 0 0 0-1.414l-6-6a1 1 0 0 0-1.414 1.414" fill-rule="evenodd"/>
                                            </svg>
                                        </div>
                                        <Link to={nextPost.pid.toString()} className="ml-auto max-w-96">
                                            <Postcard post={nextPost} className=""></Postcard>
                                        </Link>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>
        )}
        <Footer></Footer>
    </motion.div>
    )
}